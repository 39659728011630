body.webapp{
  &.pushable{ background: none !important; }
  .yield {
    h2 {
      padding: 1rem 2rem;
      background-color: $colorGrayLight;
      font-weight: 300;
      color: $colorGreen; margin-bottom: 0px;
      border-bottom: 1px solid #d4d4d4;
    }
    .search {
      .field > label { color: $colorGrayDark; font-weight: 300; }
      .input input{
        border: 1px solid transparent;
        border-bottom: 1px solid $colorGrayLight;
        @include custom-rounded(0px);
        &:focus {
          border-bottom: 1px solid $colorGreen;
          -webkit-box-shadow: none;
             -moz-box-shadow: none;
                  box-shadow: none;
          @include custom-rounded(0px);
        }
        &.bordered {
          &.green {
            border: 1px solid $colorGray;
            &:hover, &:focus, &:active { border: 1px solid $colorGreen; }
          }
        }
      }
      .button { margin-top: 1.6em; }
    }
    .ui.label { @include custom-rounded(0px); }
    a {
      color: $colorBlack;
      &:hover, &:active, &:focus { color: $colorBlack; }
      &.code {
        color: $colorGreen;
        &:hover, &:active, &:focus { color: $colorGreen; }
      }
    }
    .table {
      border-width: 0px 0px 1px 0px;
      @include custom-rounded(0px);
      thead {
        background: $colorBlack;
        th {
          @include custom-rounded(0px !important); font-weight: 300;
          color: $colorWhite;
          white-space: nowrap;
          &.action { min-width: 150px; }
        }
      }
      input[type="checkbox"], input[type="radio"]{
        margin-right: 10px;
        margin-top:3px;
        /* All browsers except webkit*/
        transform: scale(1.4);

        /* Webkit browsers*/
        -webkit-transform: scale(1.4);
      }
    }
  }
  #main-pusher{ min-height: auto; background: $colorWhite; }
  #top-nav{
    @include custom-rounded(0px);
    background: $colorGreen;
    margin-bottom: 0px;
    border-bottom: 2px solid white;
    #logo{
      height: 56px;
      padding-top: 1em;
      padding-bottom: 0.5em;
      vertical-align: bottom;
      img {
        max-height: 100%;
      }
    }
    .item{
      padding-right: 0px;
      color: $colorWhite;
      img.ic_slidemenu{
        padding-right: 10px
      }
      &:hover, &:before {
        background: transparent;
      }
      &.static { cursor: default; }
      &.last { margin: 1px 10px 0 0;}
      #working-account-name{
        // text-align: right;
        font-weight: 700;
        span { text-transform: uppercase;}
      }
      .links {
        .link {
          color: $colorWhite;
          margin: 0px 5px;
          &:first-child { margin-left: 0px; }
          &:hover, &:focus { text-decoration: underline; }
          background: #0f730f;
          padding: 6px;
        }
      }
      #working-account-email{ text-align: right;}
      .text{
        color: black!important;
        margin-bottom: 5px;
      }
      .icon{
        width: 20px;
        height: 20px;
        display: inline-block;
        &:last-child { margin-left: 5px; }
        &:first-child { margin-right: 5px; }
      }
      .icon.active{
        opacity: 0.5;
      }
      .icon.active:hover{
        opacity: 1;
      }
      .flag{
        width: 100%;
        height: auto;
      }
    }
    .right-border{
      border-right: 0.2em solid rgba(141, 212, 149, 0.68);
      padding: 0 10px 0px 10px;
    }
  }
  #menu-bar {
    width: 100%;
    background: $colorWhite;
    &.business { background: #333333;
      .menu { background: #333333;
        > .item { color: $colorWhite;
          &.active, &:hover { background: rgba(0,0,0,0.2); }
        }
      }
      .bottom-block{ background-color: #333333;
        a { color: $colorWhite; }
      }
    }
    .menu {
      width: 100%;
      background: $colorWhite;
      @include custom-rounded(0px);
      overflow-y: auto;
      box-shadow: none;
      > .item {
        cursor: pointer;
        color: $colorBlack;
        transition: background,color 0.2s;
        img { margin-bottom: 5px; }
        &:before {
          background: -webkit-linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0.05) 100%);
          background: -o-linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0.05) 100%);
          background: linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0.05) 100%);
        }
        &.active, &:hover { background: transparent; color: $colorGreen; }
      }
    }
    // Need to change padding-top
    .bottom-block{ width: 100%; bottom: 0; left: 0; background-color: $colorWhite; padding-top: 40px;
      a { color: $colorBlack;
        &.inverted { color: $colorGreen; text-decoration: underline; }
        h4 { font-size: 12px; font-weight: 400; margin: 10px; }
      }
    }
  }
  #user-sidebar{
    background: #fff;
    width: 300px;
    padding-bottom: 50px;
    .item {
      &.action {
        text-align: center;
        padding: 1.5em 0;
        &.first { margin-top: 2em; }
        a { color: $colorGreen; font-weight: 600; }
        input { color: $colorGreen; font-weight: 600; background: none; border: none;
          &:hover { text-decoration: underline; }
        }
      }
    }
    .close{
      text-align: right;
      cursor: default;
      &:hover{
        background: transparent;
      }
      + .item {
        margin-top: 56px;
        &:before {
          background: transparent;
        }
      }
      .toggle-right-sidebar{
        cursor: pointer;
        color: $colorGreen;
        font-size: 33px;
      }
    }
    #work-accounts{
      @include utility-responsive($large-screen) {
        .item{ display: table; }
      }
      .item{
        &:not(:first-child){ padding-top: 10px; }
        &:hover {
          background: transparent;
        }
        .header{
          font-weight: bold;
          margin: 10px 0px;
        }
        .content{
          padding-right: 25px;
          .company-status{
            cursor: pointer;
            position: absolute;
            top: 25px;
            right: 0;
            img{
              width:25px;
              height:25px;
            }
          }
        }
      }
    }
    .header{
      font-weight: bold;
      margin-top: 10px;
    }
    .sub-header{
      color: $colorGrayDark;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    #change-language{
      width: 100%;
      a { color: inherit; }
      .menu {
        margin-top: 10px;
        .item{
          padding: 0px !important;
          a {
            display: block;
            padding: 0.71428571em 1.14285714em !important
          }
        }
      }
      i{
        float: right;
        line-height: 30px;
        color: $colorGrayDark;
      }
      &:hover, &:focus, &:active {
        i { color: $colorGreen; }
      }
    }
    #my-info .settings {
      position: relative;
      .header { padding-right: 30px; }
      .icon {
        position: absolute;
        right: 0px;
        top: 20px;
        input[type="image"] { height: 25px; width: 25px; }
      }
    }
  }
  .modal.for-explaination {
    .content { padding-bottom: 10px; }
    .actions {
      background: none;
      text-align: center;
    }
  }
  .simple_form{
    label{
      abbr{
        display:none;
      }
    }
  }
  .list-action{
    a {
      color: $colorGreen!important;
      &:hover, &:active, &:focus { color: $colorGreen!important; }
    }
    .ui.list {
      .item {
        .image { height: 15px; width: 15px; }
        &.remove {
          .content .header { color: #ff4f47 !important; }
        }
        &.add {
          .content .header { color: #00adf5 !important; }
        }
        &.added {
          .image {
            height: 9px;
            width: 12px;
          }
          .content .header { color: #00adf5 !important; }
        }
      }
    }
  }
  .datetime-picker{
    .button {
      margin-top: 0!important;
      background-color: #f4f4f4!important;
    }
    .calendar{
      color: $colorGreen;
    }
  }
}