.service-wrapper {
  border-bottom: 1px solid #ddd;

  .Modal-FromGroup {
    border: none;
  }
}

.service-location {
  display: flex;
  padding: 15px;
  justify-content: space-between;

  .Pin {
    display: inline-block;
    line-height: 25px;
    margin-right: 15px;
    vertical-align: middle;
  }
}