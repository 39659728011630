body.single-page{
  #top-nav{
    @include custom-rounded(0px);
    background: $colorGreen;
    margin: 0px;
    #logo{
      height: 56px;
      padding-top: 1em;
      padding-bottom: 0.5em;
      vertical-align: bottom;
      img {
        max-height: 100%;
      }
    }
    .item{
      color: $colorWhite;
      &:hover, &:before {
        background: transparent;
      }
      #working-account-name{
        text-align: right; text-transform: uppercase;
        span { font-weight: 700; }
      }
      #working-account-email{ text-align: right;}
    }
  }
  #main-container {
    // background-image: image-url("background/single-page.png");
    background-color: #176215;
    background-size: cover;
    height: 100%;
    &.full-height { height: 100%; }
    .footer {
      min-height: 50px;
      background-color: #424242;
    }
    .segment {
      &.only {
        background-color: transparent !important;
        padding: 0px !important;
        box-shadow: none;
        margin-top: 56px + 15px;
        .outside-header {
          text-align: center;
          color: $colorWhite;
          margin-bottom: 1em;
          h4 { color: $colorWhite; font-weight: 300; margin: 0px; }
          h2 { color: $colorYellow; font-weight: 300; margin: 0px; }
        }
        .body {
          padding: 1.5em 2em;
          background-color: #fff;
        }
        .copyright{
          text-align: center;
          padding-top: 20px;
          padding-bottom: 15px;
          img.logo{
            padding-bottom: 10px;
          }
          p{
            font-size: 10px;
            font-weight: 300;
          }
        }
        .form-header {
          margin-bottom: 1em;
          h3 {
            color: $colorGreen;
            // text-transform: uppercase;
            font-weight: 600;
            margin-top: 0px;
            margin-bottom: 5px;
          }
          .sub_title{
            font-weight: 100;
            margin-top: 0px;
            margin-bottom: 10px;
          }
        }
        .ui.form {
          label {
            color: $colorGrayDark;
            font-weight: 300;
          }
          input:not([type]), input[type="date"], input[type="datetime-local"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="time"], input[type="text"], input[type="url"] {
            border: none;
            border-bottom: 1px solid $colorGray;
            padding: 0.3em 0.5em 0.3em 0.3em;
            &:focus { box-shadow: none; }
          }
          .ui.checkbox label {
            color: $colorBlack;
            font-size: 12px;
          }
          .button.submit-button {
            padding: 0.78571em 6.5em 0.78571em !important;
            text-transform: uppercase;
            font-weight: 300;
          }
          .field-boder {
            border: 1px solid #cccccc !important;
          }
          #show-password{
            padding: 0.3em 0.5em 0.3em 0.2em;
            font-size: 12px;
            font-weight: 100;
            .checkbox{
              margin-bottom: -1px;
              margin-top: 0px;
            }
          }
          #btn-login, #btn-stay-logged-in{
            font-weight: 100;
            font-size: 12px;
            line-height: 25px;
          }
          #btn-stay-logged-in{
            background-color: #D7EDD7 !important;
            color: $colorBlack;
            padding: 10px;
            .checkbox{
              margin-bottom: 0px;
              margin-top: 0px;
              width: 110px;
            }
          }
          .padding-left-5px{
            padding-left: 5px;
          }
          .padding-right-5px{
            padding-right: 5px;
          }
          span.float-right{
            float: right;
          }
          .green-note{
            background-color: #D7EDD7;
            color: $colorGrayDark;
            font-size: 12px;
            text-align: center;
          }
          .signup-button{
            padding: 7px 0 7px 0;
            font-weight: 100;
          }
        }
      }
      &.notice {
        background: $colorBlack;
        margin-top: 2em;
        color: $colorGrayDark;
        margin-top: 56px + 100px;
        h2 { color: $colorYellow; font-weight: 300; }
        .cs-box {
          background: transparent;
          position: absolute;
          top: -100px;
          .image.circular {
            @include custom-rounded(500rem !important);
            border: 2px solid $colorYellow;
          }
          .tool-tip {
            position: relative;
            &:before {
              content: "";
              width: 0px;
              height: 0px;
              border: 10px solid white;
              position: absolute;
              left: 1px;
              bottom: -8px;
              border-bottom: 10px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #fff;
            }
            background: #fff;
            padding: 10px;
            @include custom-rounded(5px !important);
            margin-left: 75px;
            .green { color: $colorGreen; }
          }
        }
      }
    }
  }
}