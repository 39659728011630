// TODO: I temp color var here, later then, we should move it to centralized place

:root {
  --color-light-gray: #dbdbdb;
  --color-border-red: #f04433;
  --color-border-green: #02751a;
  --color-border-gray: #a1a1a1;
  --color-background-gray: #a1a1a1;
  --color-background-light-gray: #dbdbdb;
  --color-background-green: #00b200;
  --color-background-yellow: #fedb00;
  --color-background-red: #f04433;
}

// overwrite .BookingWizard .Content .block
.BookingWizard {
  .Content {
    .shopping-list {
      .photo-attachment-block {
        margin: 15px 0px 0px 0px;

        .Dropzone {
          margin: 0px;
          border: none;
        }
      }
    }
  }
}

// Style of Shopping Step 2
.shopping-list {

  .block.shopping-block {
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    padding: 0px;
  }

  .add-item-button {
    color: var(--color-white);
  }

  .unresizable {
    resize: none;
  }

  .block-title {
    margin-bottom: 10px;
  }

  .block-title {
    margin-bottom: 10px;
  }

  .Title-bg {
    text-transform: capitalize
  }

  .shopping-list-balance-info {
    text-align: center;
    background-color: var(--color-background-yellow);
    font-size: 13px;
    line-height: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .inline-message {
    background-color: #fafafa;
    font-size: 13px;
  }
  // .shopping-block-item {
  // }
  
  .grow-2 {
    flex-grow: 2;
  }

  .shopping-left {
    display: flex;
  }

  .shopping-item:first-child:last-child {
    .remove-btn {
      cursor: auto;
    }
  }

  .shopping-list-title {
    img {
      padding-right: 10px;
    }

    span {
      color: var(--color-white);
      font-size: 15px;
    }
  }
  
  .shopping-quantity div {
    height: 24px;
    font-size: 14px;
    padding: 0px 2px;
    border: 1px solid var(--color-border-green);
    border-left: none;
    border-right: none;
  }

  .shopping-quantity {
    .shopping-quantity-text {
      line-height: 24px;
      font-size: 14px;
      padding: 0px;
      min-width: 32px;
      text-align: center;
    }
  }

  .shopping-quantity div:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
    border-left: 1px solid var(--color-border-green);
  }

  .shopping-quantity div:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
    border-right: 1px solid var(--color-border-green);
  }

  .shopping-quantity-subtract {
    // background-color: var(--color-gray);
    background-color: var(--color-background-green);
  }
  
  .shopping-quantity-subtract.disabled {
    // background-color: var(--color-gray);
    background-color: #a1a1a1;
  }
  
  .shopping-quantity-add {
    background-color: var(--color-background-green);
  }

  .status-disabled {
    // background-color: var(--color-gray);
    background-color: #a1a1a1;
  }
  

  .status-enabled {
    background-color: #44e952;
  }
  
  .shopping-quantity-add {
    background-color: var(--color-background-green);
  }

  .shopping-quantity-subtract,
  .shopping-quantity-add {
    color: var(--color-white);
    padding: 10px;
    cursor: pointer;
  }

  .photo-attachment-block {
    margin: 15px 0px 0px 0px;

    .block-item {
      height: 80px;
      width: 90px;
    }

    .Dropzone {
      margin: 0px;

      .add-photo-text {
        font-size: 12px;
        text-align: center;
      }
    }

    &.status-incompleted {
      border: 1px dashed var(--color-border-gray, #a1a1a1);
      border-radius: var(--radius);
    }

    &.status-incompleted.required {
      border: 1px dashed var(--color-border-red, #f04433);
      border-radius: var(--radius);

      .Dropzone {
        color: var(--color-border-red, #f04433);

        i {
          color: var(--color-border-red, #f04433)
        }
      }
    }

    &.status-completed {
      border: 1px solid var(--color-border-green, #40ae29);
      background-color: rgba(64, 174, 41, 0.1);
      border-radius: var(--radius);
    }

  }

  // for not_available status
  .shopping-item.not_available {
    border: 1px solid var(--color-light-gray);
    border-radius: var(--radius);

    .Title-bg {
      color: var(--color-white);
      background-color: var(--color-background-gray);
    }

    .shopping-brand,
    .shopping-desc {
      background-color: var(--color-background-light-gray);
    }

    .shopping-quantity div {
      border-color: var(--color-border-gray);
    }
    
    .shopping-quantity-subtract,
    .shopping-quantity-add {
      background-color: var(--color-background-gray);
      cursor: default;
    }
    
    .photo-attachment-block {
      border: 1px dashed var(--color-border-gray, #a1a1a1);

      .Dropzone {
        i {
          color: var(--color-gray);
        }
      }

      .Icon,
      .add-photo-text {
        color: var(--color-gray);
      }
    }
  }

  .shopping-item.pending {
    border: 1px solid var(--color-border-red);
    border-radius: var(--radius);

    .Title-bg {
      color: var(--color-white);
      background-color: var(--color-background-red);
    }
  }

  .shopping-item.confirmed {
    border: 1px solid var(--color-border-green);
    border-radius: var(--radius);

    .Title-bg {
      color: var(--color-white);
      background-color: var(--color-background-green);
    }
  }

  .shopper-info {
    background-color: #f1f1f1;
    font-weight: bold;
    font-size: 13px;

    .shopper-info-title {
      font-weight: normal;
    }

    .left div {
      padding-bottom: 10px;
    }

    .left div:last-child {
      padding-bottom: 0px;
    }

    .brand.not-same-brand,
    .quantity.not-same-quantity {
      color: var(--color-red)
    }
  }
}
