.webapp {
  .ui.scrolling.modal {
    @include custom-rounded(0px);
    margin-top: 10% !important;
    &.my-small, &.my-normal {
      > .close {
        top: 15px;
        right: 0;
        background: transparent image-url("../../images/icon-v1/close-big.png") no-repeat;
        padding: 0px;
        &:before {content: none;}
      }
      > .header {
        padding: 1rem;
        background: $colorGrayLight;
        color: $colorBlack;
        border-bottom: 1px solid $colorGray;
        @include custom-rounded(0px);
        text-align: left;
        font-weight: 700;
        font-size: 1em;
      }
      .actions {
        padding: 1rem 1rem;
      }
      table.locations-info {
        width: 100%;
        tr {
          &:not(:last-child) td { border-bottom: 1px solid $colorGrayLight; }
          td {
            padding: 5px 0px;
            .address { margin: 5px 0px 10px; }
            .green { color: $colorGreen; }
            .square-checkbox { display: inherit; margin-bottom: 5px; }
            .input {
              padding-left: 30px;
              margin-bottom: 5px;
              input {
                border: none;
                background: $colorGrayLight;
                color: $colorGrayDark;
                border-radius: none;
              }
            }
            &:first-child {
              vertical-align: top;
              padding-right: 10px;
              width: 28px;
              img { height: 28px; }
            }
          }
        }
      }
      // fee-details table
      .fee-details {
        padding-bottom: 10px;
        .ui.list {
          margin-bottom: 0px;
          padding: 10px 10px;
          // background-color: $colorGrayLight;
          margin-top: 0px;
          .ui.list { padding-right: 0px; color: $colorBlack; }
          .bold { font-weight: 600; }
          &:not(.first) {
            padding-top: 0px;
            .item:first-child { padding-top: 10px; border-top: 1px solid $colorGrayDark; }
          }
          .title {
            &.discount { color: $colorGreen; }
            &.total-fee { font-weight: 700; }
          }
          .fee.total{
            color: $colorGreen;
            font-weight: 300;
            font-size: 44px;
          }
          .info{ color: $colorGrayDark; }
        }
      }
    }
    > .header {
      padding: 1rem;
      background-color: $colorGreen;
      color: $colorWhite;
      border-bottom: none;
      @include custom-rounded(0px);
      text-align: center;
      font-weight: 300;
    }
    > .content {
      padding: 1em 1em 0px;
      .image.tiny { height: 85px; width: 85px; }
      .black { color: $colorBlackDark; }
      .gray { color: $colorGrayDark; }
      .icon img { height: 40px; }
      .static-react-rater {
        direction: rtl;
        .fa { color: $colorGreen; font-size: 1.5em; letter-spacing: 0.2em; }
      }
      // .vehicle-color { margin-left: 10px; }
      .banner-photo {
        // margin: 10px auto;
        // width: 100%;
      }
      h3.ui.header {
        margin: 0px;
        font-weight: 600;
      }
    }
    .actions {
      background-color: transparent;
      border-top: none;
      text-align: center;
      .button { min-width: 150px;
        &.fluid { margin-left: 0px; }
      }
    }
    .non-actions {
      background-color: transparent;
      border-top: none;
      text-align: center;
      padding: 1rem 2rem;
      .button { min-width: 150px; }
    }
  }
  // new session modals
  #new-session-modals {
    .padding-left-5px{ padding-left: 5px; }
    .padding-right-5px{ padding-right: 5px; }
    .padding-top-5px{ padding-top: 5px; }
    .padding-bottom-5px{ padding-bottom: 5px; }
    .ui.scrolling.modal {
      // signin modal
      &#signin-modal, &#signup-modal {
        .checkbox{
          margin-bottom: -1px;
          margin-top: 0px;
          label {
            font-size: 12px;
            &:before, &:after {
              border-color: $colorGreen;
              @include custom-rounded(0px);
            }
            &:after {
              color: $colorGreen;
            }
          }
        }
        .link, a {
          color: $colorGreen;
          cursor: pointer;
          &:hover, &:focus { color: $colorGreen; text-decoration: underline; }
        }
        .copyright{
          text-align: center;
          padding-bottom: 15px;
          img.logo{
            padding-bottom: 10px;
            width: 110px;
          }
          p{
            font-size: 10px;
            font-weight: 300;
          }
        }
        > .header {
          padding: 0.2em 1em;
          background: $colorWhite;
          color: $colorGreen;
          font-weight: 600;
          h5 { color: $colorBlack; font-weight: 300; margin-top: 5px; }
        }
        > .content {
          padding: 0px 1em;
          .ui.form {
            label {
              color: gray;
              font-weight: 300;
            }
            input[type="text"], input[type="password"] {
              padding: 0.3em 0.5em 0.2em 0.3em;
              border: 1px solid $colorGray;
              @include custom-rounded(0px);
            }
            #show-password{
              padding: 0.3em 0.5em 0.2em 0.2em;
              font-size: 12px;
              font-weight: 100;
              color: $colorBlack;
              @include custom-rounded(0px);
              .checkbox{
                margin-bottom: -1px;
                margin-top: 0px;
              }
            }
          }
        }
        .actions {
          padding: 1em;
          #btn-login, #btn-stay-logged-in{
            font-weight: 100;
            font-size: 12px;
            line-height: 25px;
          }
          #btn-stay-logged-in{
            background-color: #D7EDD7;
            color: $colorBlack;
            padding: 10px;
            @include custom-rounded(0px);
            .checkbox{
              margin-bottom: 0px;
              margin-top: 0px;
              width: 97px;
            }
          }
        }
      } // end signin modal
      // choose account modal
      &#choose-account-modal {
        > .header {
          background: $colorGreen;
          color: $colorWhite;
          h4 { color: $colorWhite; font-weight: 300; margin: 0px; }
          h3 { color: $colorYellow; font-weight: 300; margin: 0px; background: transparent;}
        }
        > .content {
          padding: 1.5em 2em;
          background-color: $colorWhite;
        }
        .choose-account {
          .item {
            @include custom-rounded(0px);
            position: relative;
            .icon.chevron.right {
              position: absolute;
              top: 50%;
              margin-top: -7px;
              right: 0;
            }
          }
        }
      }
      // choose account modal
      &#go-to-step-1-modal {
        .content { padding: 1.5em 2em 0px; text-align: center; }
      }
      // forgot password modal
      &#forgot-password-modal {
        .actions { padding: 1em; }
      }
    }
  }
}

.ui.modal.event-baner-modal {
  background: transparent;
  img {
    width: 100%;
    border-radius: 0px !important;
  }
}

#full-day-modal {
  .body-text {
    white-space: pre-wrap;
    margin-bottom: 10px;
    a { text-decoration: underline; }
  }
  .sub-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}