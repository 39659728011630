//= require_self

//= require animsition
//= require semantic-ui/dist/semantic
//= require alertify/themes/alertify.default
//= require alertify/themes/alertify.core

#authentication-page {
  .login-form { margin-top: 40px;
    .form.segment { border-radius: 0px; }
    .logo { margin: 5px; max-width: 100%; }
    .submit-button { width: 100%; border-radius: 0px; }
    .green-link { color: $colorGreenLight; font-weight: 700; }
    p.description { color: $colorGreenLight; font-weight: 300;
      a { font-weight: 700; color: $colorGreenLight; text-decoration: underline; }
    }
    .sub-panel {
      p { color: $colorGray; }
      a { color: #fff; text-decoration: underline; }
    }
    input:not(.button) { background-color: $colorGrayLight; border-radius: 0px; line-height: normal;}
    .c-staff-login-social {
      a {
        flex: 1;
        border: 0 none;
        border-radius: 4px;
        outline: 0;
        transition: 200ms ease-in-out;
        display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .social-google {
        padding: 10px 0;
        background-color: #fff;
        box-shadow: 0 0 10px #bbbbbb;
        span {
          padding-left: 4px;
        }
      }
    }
  }
}

.background-image {
  // @include custom-background-body('background-preload3', 'png');
}

.language-selection { position: absolute; right: 2%; top: 2%; z-index: 300;
  label { margin-right: 10px; color: $colorWhite; }
  select { display: inline-block; width: 100px; }
}
