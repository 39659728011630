.wallet-screens {
  padding-bottom: 30px;
  margin-bottom: 130px;

  &.no-transactions {
    padding: 0;
    margin: 0;
  }

  .Empty-List {
    height: calc(100vh - 65px);
  }
}

.credit-available {
  height: 40px;
  color: #282828;
  font-size: 14px;
  background-color: #e5e5e5;
  border-radius: 6px;
  margin-left: 20px;
}

@media (min-width: 1366px) {
  .wallet-screens {
    .Empty-List {
      height: calc(100vh - 215px);
    }
  }
}