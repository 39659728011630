.Popup.popup-price {
  .Box-Content {
    display: block;
    padding: 0;
    width: 360px;
  }
  .Normal {
    padding: 0 20px 20px 20px;
  }
  .Popup .Pricing {
    width: unset;
  }
  .popup-price__version {
    background-color: var(--color-background-yellow);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 10px 15px;
    color: #444444;
    font-size: 13px;
    width: 100%;
    height: auto;
  }
  &.popup-price__custom {
    .Normal {
      padding: 0 20px 0 20px;
    }
    .Box-Content  {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}