.header-bar {
  .menu-item { 
    border-radius: 5px;
    padding: 9px 18px 9px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #ffffff;
    margin:0 8px;
    overflow: hidden;
    font-size: 16px;
    &:hover, &.active {
      color: #ffffff;
      background-color: #0d6b0e;
      cursor: pointer;
    }
    &:hover .triangle-right{
      display: none;
    }
  }
  .button-single { 
    &:hover {
      color: #ffffff;
      background-color: #0d6b0e;
    }
  }
  .menu-item .button-single{font-size: 16px;}
  .tooltip .tooltip-text {
    visibility: hidden;
    background-color: #fff;
    color: #282828;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;
    position: absolute;
    z-index: 1;
    top: 60px; 
    display: flex;
  }
  .tooltip .tooltip-text .ctn-box1::before, .tooltip .tooltip-text .ctn-box1::after{
    content: "";
    position: absolute;
    bottom: 10px;
    border-left: solid 1px #f1f1f1;
    height: 88%;
    background: #f1f1f1;
    width: 0.5px;
  }
  .tooltip .tooltip-text .ctn-box1::before{
    left: 33%;
  }
  .tooltip .tooltip-text .ctn-box1::after{
    right: 33%;
  }
  .tooltip .tooltip-text .row-item{
    display: flex;
  }
  .tooltip .tooltip-text .row-item.ctn-box{
    margin: 13px 0 17px 0;
  }
  .tooltip .tooltip-text .wr-box.ctn-box1 .row-item .item:first-child{
    padding-right:20px;
  }
  .tooltip .tooltip-text .wr-box.ctn-box1 .row-item .item:last-child{
    padding-left:20px;
  }
  .tooltip .tooltip-text .row-item .item{
    width: 100%;
    max-width: 230px;
    margin: auto 0;
    font-size: 13px;
    color: #282828;
    //font-family: "Roboto-Regular";
  }
  .tooltip .tooltip-text .wr-box{
    width: 100%;
    padding: 5px 20px; 
  }
  .tooltip .tooltip-text .wr-box a{
    padding: 10px 29px 9px 29px;
    border-radius: 6px;
    background-color: #40ae29;
    font-size: 18px;
    line-height: 18px;
    color: #fff;
    font-family: "roboto";
    height: auto;
    font-weight: 500;
  }
  .tooltip .tooltip-text .wr-box a img{
    margin-left: 5px;
  }
  
  .tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
  .tooltip .tooltip-text::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -50px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent ;
    width: 80px;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }

  .Menu-Nav.left .Menu-Nav-Item:not(.Devina-Screen):before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #9fd484;
    left: 0;
    height: 60%;
    margin: auto;
  }
  .triangle-right {
    width: 0;
    height: 0;
    position: relative;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    text-indent: -9999999999999px;
  }
  
  .triangle-right:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
    position: absolute;
    top: 0px;
    right: 8px;
  }
  .tooltip .tooltip-alert {
    visibility: hidden;
    background-color: #ebf6e9;
    color: #282828;
    text-align: center;
    border-radius: 0 0 8px 8px;
    line-height: 14px;
    position: absolute;
    z-index: 1;
    top: 60px;
    display: flex;
    max-width: 140px;
    font-size: 13px;
    padding: 10px;
  }
  .tooltip .tooltip-alert::before{
    content: "";
    position: absolute;
    left: 36%;
    border: 1px solid #fff;
    width: 50px;
    top: -7px;
  }

  .Dropdown-Head-Arrow,
  .Dropdown-Menu {
    display: none;
    // background: #fff;
    position: absolute;
    top: 100%
  }
  .Dropdown.dark-green .Dropdown-Menu-Item {
    color: #fff
  }
  .Dropdown.dark-green .Dropdown-Menu-Item:hover {
    color: var(--color-yellow)
  }
  .Dropdown-Menu-Item:first-child {
    border: none;
  }
  
  .Dropdown-Menu {
    box-shadow: var(--box-shadow);
    border-bottom-left-radius: var(--radius-s);
    border-bottom-right-radius: var(--radius-s);
    // left: 0;
    margin: 0 auto;
    width: auto;
    white-space: nowrap;
    z-index: 10000;
    min-width: 120px
  }

  .Menu-Nav.left .Menu-Nav-Item:not(.Devina-Screen) {
    position: relative;
    padding-left: 18px;
    padding-right: 13px;
    &.active .Dropdown .Dropdown-Head{
      color: #ffffff;
      background-color: #0d6b0e;
      padding: 7px 10px;
      border-radius: 5px;
      height: auto;
    }
  }
  .Dropdown-Close {
    display: none;
    background: hsla(0, 0%, 100%, 0);
    content: "";
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
 }
  .Dropdown.open .Dropdown-Menu {
    max-height: 500px;
    transition: max-height 0.2s ease-in;
  }
  .Dropdown.open .Dropdown-Head-Arrow, .Dropdown.open .Dropdown-Menu{
    display: none;
  }

  .Dropdown-Head-Arrow {
    width: 12px;
    height: 12px;
    left: 50%;
    margin: -3px 0 0 -6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 10001;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, .1)
  }
  
  .Menu-Nav-Item .Dropdown {
    display: flex;
    position: relative;
    height: inherit;
    align-items: center;
    &:hover .Dropdown-Menu{
      display: block;
    }
    &:hover .Dropdown-Head-Arrow{
      display: block;
    }
  }
  .Menu {
    .logo-link {
      margin-left: 35px;
      display: flex;
    }
  }
  .Menu a, .Menu a .Button, .Menu .TitleSubtitle-title, .Dropdown-Head-Item{
    font-size: 16px;
  }
}