//= require_self

//= require animsition
//= require semantic-ui/dist/semantic
//= require alertify/themes/alertify.default
//= require alertify/themes/alertify.core
//= require eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker

body.single-page{
  overflow-x: hidden;
  background-color: #176215;
  * { border-radius: 0px !important; }
  .menu.languages {
    &:last-child { margin-right: 15px; }
    .item { padding: 1.4em 0.4em; }
    .text_languages { font-size: 0.86rem; }
    @media (max-width: 991px) {
      .text_languages { display: none; }
    }
  }
  #main-container {
    @include utility-responsive(1250px) {
      .segment.notice {
        .cs-box {
          width: 400px;
          margin: auto;
          box-shadow: none;
          position: absolute;
          right: -370px;
          top: -60px;
          z-index: 1;
        }
      }
    }
    @include utility-responsive(768px) {
      background-color: #176215;
      background-repeat: no-repeat;
      background-position: center center;
      background-position-y: center;
      background-attachment: fixed;
      background-size: cover;
      > .ui.grid:first-child:not(.no-background) {
        // background: image-url("background/cars.png") no-repeat fixed center 190px;
        background-repeat: no-repeat;
        background-position: center 190px;
        background-attachment: fixed;
        background-size: auto;
        min-height: 500px;
      }
      .segment {
        &.only {
          width: 400px;
          margin: auto;
          margin-top: 56px + 70px;
        }
        &.notice {
          width: 400px;
          margin: auto;
          margin-top: 56px + 100px;
        }
      }
    }
    a {
      color: $colorGreen;
      &:hover { color: $colorGreen; }
    }
    .ui.checkbox .box,
    .ui.checkbox label {
      &:before {
        border-color: $colorGreen;
        @include custom-rounded(0px);
      }
      &:after {
        color: $colorGreen;
      }
    }
    .choose-account {
      .item {
        position: relative;
        .icon.chevron.right {
          position: absolute;
          top: 50%;
          margin-top: -7px;
          right: 0;
        }
      }
    }
  }
}
