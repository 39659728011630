body.single-page {
  .ui.scrolling.modal {
    @include custom-rounded(0px);
    margin-top: 10% !important;
    &.my-small {
      > .close {
        top: 15px;
        right: 0;
        background: transparent url("../../images/icon-v1/close-big.png") no-repeat;
        padding: 0px;
        &:before {content: none;}
      }
      > .header {
        padding: 1rem;
        background: $colorGrayLight;
        color: $colorBlack;
        border-bottom: 1px solid $colorGray;
        @include custom-rounded(0px);
        text-align: left;
        font-weight: 700;
        font-size: 1em;
      }
      .actions {
        padding: 1rem 1rem;
      }
      table.cod-pod {
        width: 100%;
        tr {
          &:not(:last-child) td { border-bottom: 1px solid $colorGrayLight; }
          td {
            padding: 5px 0px;
            .address { margin: 5px 0px 10px; }
            .square-checkbox { display: inherit; margin-bottom: 5px; }
            .input {
              padding-left: 30px;
              margin-bottom: 5px;
              input {
                border: none;
                background: $colorGrayLight;
                color: $colorGrayDark;
                border-radius: none;
              }
            }
            &:first-child {
              vertical-align: top;
              img { height: 28px; }
            }
          }
        }
      }
    }
    > .header {
      padding: 1rem;
      background-color: $colorGreen;
      color: $colorWhite;
      border-bottom: none;
      @include custom-rounded(0px);
      text-align: center;
      font-weight: 300;
    }
    > .content {
      padding: 1em 1em 0px;
      .image.tiny { height: 85px; width: 85px; }
      .black { color: $colorBlackDark; }
      .gray { color: $colorGrayDark; }
      .icon img { height: 40px; }
      .static-react-rater {
        direction: rtl;
        .fa { color: $colorGreen; font-size: 1.5em; letter-spacing: 0.2em; }
      }
      .vehicle-color { margin-left: 10px; }
      .banner-photo {
        margin: 10px 0px;
      }
    }
    .actions {
      background-color: transparent;
      border-top: none;
      text-align: center;
      .button { min-width: 150px;
        &.fluid { margin-left: 0px; }
      }
    }
    .non-actions {
      background-color: transparent;
      border-top: none;
      text-align: center;
      padding: 1rem 2rem;
      .button { min-width: 150px; }
    }
  }
}