//= require_self
//= require client
//= require landing_page

//= require animsition
//= require semantic-ui/dist/semantic
//= require react-rater
//= require alertify/themes/alertify.default
//= require alertify/themes/alertify.core
//= require selectize/dist/css/selectize
//= require eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker

.no-padding { padding: 0 !important; }
.no-margin { margin: 0 !important; }
.pull-right { float: right !important; }
.pull-left { float: left !important; }
.upload-image {
  border: 1px solid $colorGrayLight;
  .dimmer {
    input[type="file"] { opacity: 0; visibility: hidden !important; position: absolute; z-index: -1; }
  }
}
.btn{
  &.btn-bordered{
    background-color: $colorWhite; color: $colorGreenLight; cursor: pointer;
    border: 1px solid $colorGreenLight;
    &.inverse{
      color: black;
      border: 1px solid $colorGrayLight;
    }
  }
  &.btn-filled{
    background-color: $colorGreenLight; color: $colorWhite; cursor: pointer;
  }
}