body.webapp {
  .list-view {
    .ui.tabular.menu {
      padding: 0 2rem;
      border-bottom: 2px solid $colorGreen;
      margin-top: 1rem;
      .item {
        @include custom-rounded(0px);
        background-color: $colorGrayLight;
        color: $colorGrayDark;
        border-width: 0.5px 0.5px 0px;
        border-color: #d4d4d4;
        &:not(:first-child) { border-width: 0.5px 0.5px 0px 0px; }
        &.active {
          background-color: $colorGreen;
          color: $colorWhite;
          border-width: 0.5px 0.5px 0px;
          border-color: $colorGreen;
          font-weight: 300;
          margin-bottom: 0px;
        }
      }
    }
    .tab.segment, .list-body {
      padding: 2em;
      border: none;
    }
    .table {
      border-width: 0px 0px 1px 0px;
      thead {
        background: $colorBlack;
        th {
          @include custom-rounded(0px !important);
          font-weight: 500;
          color: $colorWhite;
          white-space: nowrap;
          &.action { min-width: 150px; }
        }
      }
    }
    .action-box {
      margin: 32px 2rem 19px;
    }
    .breadcrumb {
      margin: 25px 2rem 46px;
      padding: 0px;
      background: none;
      a.section { color: #949494; }
      .section.active { color: #444444; }
      .divider { color: #949494; }
    }
    .text_hight_ligh{
      // color: $colorRedLight;
      font-weight: bold;
      margin-right: 5px;
      text-transform: capitalize;
    }
    .inside_hyperlink{
      // color: $colorRed;
      margin-top: 10px;
      position: absolute;
    }

    .column.six.wide.reset_apikey_field{
      display: none;
    }

    .ui.list .item a.hyperlink_blue_color, a.hyperlink_blue_color{
      color: $colorBlueLink !important;
    }

    .ui.list .item a.hyperlink_green_color, a.hyperlink_green_color{
      color: $colorGreenButton !important;
    }

    .ui.grid .edit_webhook_url_field{
      display: none;
    }

    .ui.grid .edit_webhook_authentication_key_field{
      display: none;
    }
    .bottom_margin{
      margin-bottom: 100px;
    }
    .update_content_data_type_btn{
      margin-left: 20px;
    }
    .button_green_color{
      background-color: $colorGreenButton !important;
      color: #fff !important;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
      font-size: 12px;
    }
    .field_bg{
      background-color: $colorbgGrey !important;
      color: $colorBlack;
      border-radius: 2px;
      border: none !important;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    }
    .api_button_color{
      color: $colorBlack !important;
      background-color: #cacaca !important;
      font-size: 12px;
    }
  }
}