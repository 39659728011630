body.webapp {
  #new-booking-column { display: inline-block;}
  // STEP BUTTONS BLOCK
  #step-buttons {
    > .column { background-color: $colorGrayLight; }
  }
  #new_booking {
    max-width: none;
    .ui.input input, .dropdown, .dropdown .menu, textarea { @include custom-rounded(0px); }
    label { font-weight: 400; }
    #step-header-buttons {
      background: $colorGrayLight;
      * { transition: none !important; }
      .button {
        background: transparent;
        color: $colorGrayDark;
        font-weight: normal;
        @include custom-rounded(0px);
        &:hover, &:focus, &:active {
          background: transparent;
          color: $colorGrayDark;
          box-shadow: none !important;
        }
        &.active {
          background: $colorGreen;
          color: $colorWhite;
          &:not(:last-child) {
            &:after{
              content: '';
              position: absolute;
              top: 0;
              right: -18px;
              border-top: 18px solid transparent;
              border-bottom: 18px solid transparent;
              border-left: 18px solid $colorGreen;
            }
          }
          &:not(:first-child) {
            &:before{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              border-top: 18px solid $colorGreen;
              border-bottom: 18px solid $colorGreen;
              border-left: 18px solid $colorGrayLight;
            }
          }
        }
      }
    }
    .form-content-box {
      margin: 0px -10px;
      padding: 0px 10px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .form-content {
      background: $colorWhite;
      // padding: 15px;
      > div { max-height: 100%; overflow: hidden; padding: 15px 15px 0px; }
      label { margin-bottom: 5px; display: block; }
      .component { margin-bottom: 10px; border-bottom: 1px solid $colorGrayLight;
        &.bordered {
          // border: 1px solid $colorGray;
          // .horizontal-line{border-color: $colorGray;}
          input, textarea {
            border-radius: 0px;
            border: none;
            background-color: $colorGrayLight;
            color: $colorGrayDark;
          }
        }
      }
      // LOCATIONS BLOCK
      .locations {
        border-bottom: 1px solid $colorGrayLight;
        margin: 0px -15px 10px;
        > .header { margin-left: 15px; font-weight: 700; font-size: 15px}
        .input {
          &.first{
            input{ border-top: 1px solid $colorGrayLight; }
          }
          width: 100%;
          input{
            width: 100%;
            // background-color: ;
            border: none;
            border-bottom: 1px solid ;
            padding-top: 7px;
            padding-bottom: 7px;
            margin: 0px 0px 10px 10px!important;
            padding-left: 5px !important;
            padding-right: 5px !important;
            font-weight: 300;
            @include custom-rounded(0px);
            // background-repeat: no-repeat;
            // background-size: 25px;
            // background-position: 15px 4px;
            background: -webkit-linear-gradient($colorGray, $colorGray), -webkit-linear-gradient($colorGray, $colorGray), -webkit-linear-gradient($colorGray, $colorGray);
            background: -o-linear-gradient($colorGray, $colorGray), -o-linear-gradient($colorGray, $colorGray), -o-linear-gradient($colorGray, $colorGray);
            background: linear-gradient($colorGray, $colorGray), linear-gradient($colorGray, $colorGray), linear-gradient($colorGray, $colorGray);
            background-size: 1px 20%, 100% 1px, 1px 20%;
            background-position: bottom left, bottom center, bottom right;
            background-repeat: no-repeat;
            border: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:focus, &:hover {
              background: -webkit-linear-gradient($colorGreen, $colorGreen), -webkit-linear-gradient($colorGreen, $colorGreen), -webkit-linear-gradient($colorGreen, $colorGreen);
              background: -o-linear-gradient($colorGreen, $colorGreen), -o-linear-gradient($colorGreen, $colorGreen), -o-linear-gradient($colorGreen, $colorGreen);
              background: linear-gradient($colorGreen, $colorGreen), linear-gradient($colorGreen, $colorGreen), linear-gradient($colorGreen, $colorGray);
              background-size: 1px 20%, 100% 1px, 1px 20%;
              background-position: bottom left, bottom center, bottom right;
              background-repeat: no-repeat;
              @include custom-rounded(0px);
              box-shadow: none;
              -moz-box-shadow: none;
              -webkit-box-shadow: none;
            }
          }
          &:hover {
            .location-current-icon, .select-contact-icon, .horizontal-line-left, .horizontal-line-right{ opacity: 1; transition: opacity 0.3s; }
          }
          .location-icon {
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin: 4px 0px 0px 15px;
          }
          .location-current-icon, .select-contact-icon, .horizontal-line-left, .horizontal-line-right{ opacity: 0; }
          .show-on-dimmer { z-index: 2001; position: absolute; right: 5px; opacity: 1; background: white; border-radius: 50%;}
          .popup {
            max-width: 100%;
            width: 95%;
            right: 15px!important;
            .ui.search {
              .ui.input {
                input { padding: 7px 30px 7px 0px !important; }
              }
              .results { width: 100%;
                @include custom-rounded(0px);
                .result {
                  .title { font-weight: 300; }
                  .description { color: $colorBlack; }
                }
              }
            }
          }
        }
        .additional-block {
          margin: 5px 0px 12px 50px;
          #optimize-button{
            width: 50%;
            color: $colorGreen;
            transition: color 0.2s;
            min-height: 18px;
            .square {
              margin-right: 8px;
              width: 16px;
              height: 16px;
              margin-top: -2px;
            }
            * { cursor: pointer; }
          }
          #add-location{
            width: 50%;
            float: right;
            .button {
              color: $colorGreen;
              cursor: pointer;
              transition: color 0.2s;
              font-size: 1rem;
              .fa {
                width: 24px;
                text-align: center;
              }
              &:hover {
                color: $colorGreen;
              }
            }
          }
        }
      }
      // SERVICE TYPES BLOCK
      .service-types {
        .service-type{
          margin-bottom: 10px;
          label { cursor: pointer; }
        }
        .header{font-weight: 700; font-size: 15px;}
      }
      // VEHICLE TYPES BLOCK
      .vehicle-types {
        .vehicle-type{
          margin-bottom: 10px;
          label { cursor: pointer; }
        }
        .header{font-weight: 700; font-size: 15px;}
        &.hide-content >:not(.never-hide){
          display: none;
        }
      }
      // PICKUP TIME BLOCK
      .pickup-time {
        .full-day-block {
          display: none;
        }
        .time-type{
          margin-bottom: 10px;
          label { cursor: pointer; }
        }
        .datetimepicker-component{
          // margin-top: 10px;
          input {
            @include custom-rounded(0px);
            &:disabled { background: $colorGrayLight; }
          }
        }
        .header{font-weight: 700; font-size: 15px;}
      }
      // PICKUP TIME INFO BLOCK
      .simple-info {
        &.first { padding-top: 10px; }
        &.last { padding-bottom: 12px; margin-bottom: 10px;}
        background: white;
        margin: -8px -15px 0px;
        padding: 5px 0 8px 12px;
        .title{
          font-weight: bold;
        }
        .header{
          font-weight: 700;
          font-size: 15px;
        }
        .extra-info{ color: $colorGreen; font-size: 12px; }
        .ui.list {
          .item {
            .title {
              width: 150px; display: inline-block;
              + .ui.grid {
                margin: 5px -1rem 0px;
                .column {
                  padding: 0px 1rem;
                }
              }
            }
            .content { display: inline-block; }
            .right {
              margin-right: 10px!important;
            }
          }
        }
        .ui.selection.dropdown {
          min-width: auto;
          .dropdown.icon { outline: none; }
          &.disabled { opacity: 0.7 !important; }
        }
      }
      // PICKUP TIME INFO BLOCK
      .extra-requirements {
        .header{
          font-weight: 700;
          font-size: 15px;
        }
        .extra-info{ color: $colorGreen; font-size: 12px; }
        .checkbox {
          margin-top: 0px;
          .square { margin-right: 0px; }
        }
        .ui.selection.dropdown {
          min-width: auto;
          .dropdown.icon { outline: none; }
          &.disabled { opacity: 0.7 !important; }
        }
        .ui.list {
          margin-top: 5px;
          // padding-bottom: 10px;
        }
        .explaination {
          display: inline-block; margin-left: 15px; cursor: pointer;
        }
        .cod-and-pod {
          .fee-info {
            position: relative;
            cursor: pointer;
            .fee { color: $colorGreen;
              margin-right: 30px;
              .amount { padding-left: 5px; }
            }
            .arrow {
              background: transparent image-url("../../images/icon-v1/arrow-right.png") no-repeat;
              height: 20px;
              width: 20px;
              top: -3px;
              right: 8px;
              display: inline-block;
              position: absolute;
            }
          }
        }
      }
      // DISCOUNT CODE BLOCK
      .discount-code {
        padding-bottom: 10px;
        .header{
          font-weight: 700;
          font-size: 15px;
        }
      }
      // NOTE BLOCK
      .note {
        padding-bottom: 10px;
        #note { height: initial !important; resize: none; }
        .header{
          font-weight: 700;
          font-size: 15px;
        }
      }
      // PICTURES BLOCK
      .pictures {
        padding-bottom: 10px;
        .image {
          position: relative;
          cursor: pointer;
          img {
            width: 98px;
            height: 98px;
          }
          &:hover {
            .text-holder { display: block; }
          }
          .text-holder {
            display: none;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100px;
            width: 100px;
          }
          .file-name { white-space: nowrap; width: 100%; overflow: hidden; text-overflow: ellipsis; }
        }
      }
      // FAVORITE BLOCK & SEND FIRST TO FAVORITE DRIVERS
      .favorite {
        padding-bottom: 10px;
        margin: 0px -15px;
      }
      .send-to-favorite {
        margin: 0px -15px;
      }
      // FEE DETAILS BLOCK
      .fee-details {
        padding-bottom: 10px;
        .header{
          font-weight: 700;
          font-size: 15px;
        }
        .ui.list {
          margin-bottom: 0px;
          padding: 10px 10px;
          background-color: $colorGrayLight;
          margin-top: 0px;
          .ui.list { padding-right: 0px; }
          // &.first { border-top: 1px solid $colorGreen; }
          .bold { font-weight: 600; }
          &:not(.first) {
            padding-top: 0px;
            .item:first-child { padding-top: 10px; border-top: 1px solid $colorGrayDark; }
          }
          .title {
            &.discount { color: $colorGreen; }
            &.total-fee { font-weight: 700; color: $colorGreen; font-size: 34px;}
          }
          .fee.total{
            color: $colorGreen;
            font-weight: 300;
            font-size: 44px;
          }
          .info{ color: $colorGrayDark; }
        }
      }
      // PICKUP TIME INFO BIG BLOCK
      .pickup-time-info-big {
        padding-bottom: 10px;
        .type {
          //text-transform: uppercase;
          font-weight: 700;
          font-size: 15px;
        }
        .time{ color: $colorGrayDark; line-height: 25px; }
      }
      // LOCATION DETAILS BLOCK
      .location-details {
        padding-bottom: 10px;
        .ui.list {
          margin-left: 40px;
          .item {
            &:last-child {
              .line { display: none; }
            }
            position: relative;
            .pin-icon {
              position: absolute;
              top: 0px;
              left: -40px;
              z-index: 2;
            }
            .line{
              position: absolute;
              z-index: 1;
              top: 0px;
              left: -29px;
              height: 100%;
              width: 2px;
              background-color: #ccc;
            }
            .content {
              .header {
                margin: 0px 0px 10px;
                font-weight: 400;
                font-size: 16px;
              }
              .ui.input {
                input { width: 200px; }
                img {
                  height: 19px;
                  margin: 7px 12px 0 2px;
                }
              }
              .radio{
                &.left-labeled{
                  img{ height: 14px; display: inline-block; opacity: 0.6; }
                  label{
                    display: inline-block;
                    position: relative;
                    padding-left: 10px;
                    color: $colorGrayDark;
                    input[type="radio"]{
                      margin-left: 0px;
                      right: -20px;
                      top: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      // OTHER INFO BLOCK
      .other-info {
        padding-bottom: 10px;
        .header{
          font-weight: 700;
          font-size: 15px;
        }
        .title{
          font-weight: bold;
        }
        .ui.list {
          // padding-top: 10px;
          // border-top: 1px solid $colorGrayLight;
          .item {
            .title {
              width: 150px; display: inline-block;
              + .ui.grid {
                margin: 5px -1rem 0px;
                .column {
                  padding: 0px 1rem;
                }
              }
            }
            .content { display: inline-block; }
            img { margin: auto; }
            .file-name { white-space: nowrap; width: 100%; overflow: hidden; text-overflow: ellipsis; }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget{
      width: 93%;
      .datepicker {
        z-index: 51;
      }
      .timepicker{
        width: 50%!important;
        z-index: 50;
      }
    }
  }
}

.driver-count {
  list-style: none;
  overflow: hidden;
  left: 41%;
  top: 88%;
  position: absolute;
  // z-index: 1;
  border-radius: 10px;
  padding-left: 0px;
  box-shadow: 0 7px 10px -6px darkgrey;
  opacity: 0.9;
  background: #f5d60f;
  border: 3px solid white;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2em;
}

.driver-count li {
  float: left;
  display: flex;
  text-align: center;
}

.driver-count li span {
  text-decoration: none;
  // padding: 4px 4px 4px 4px;
  // background: #FBD81D;
  position: relative;
  display: block;
  float: left;
  margin: auto;
}

// .driver-count li span:after {
//   content: " ";
//   display: block;
//   width: 0;
//   height: 0;
//   border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
//   border-bottom: 50px solid transparent;
//   // border-left: 30px solid #F6D32F;
//   position: absolute;
//   top: 50%;
//   margin-top: -50px;
//   left: 100%;
//   z-index: 2;
// }

// .driver-count li span:before {
//   content: " ";
//   display: block;
//   width: 0;
//   height: 0;
//   border-top: 50px solid transparent;
//   border-bottom: 50px solid transparent;
//   // border-left: 30px solid #D9B219;
//   position: absolute;
//   top: 50%;
//   margin-top: -50px;
//   margin-left: 2px;
//   left: 100%;
//   z-index: 1;
// }

.driver-count li:first-child span {
  padding-left: 10px;
  border-radius: 3px 0px 0px 3px;
}

.driver-count li:last-child span {
  padding: 0 5px 0 2px;
  border-radius: 0px 3px 3px 0px;
}

.driver-count li:nth-child(2) span {
  word-wrap: break-word;
  line-height: 18px;
}
.li_id{
  width: 6em;
}
.li_other{
  width: 4em;
}
#num-driver, #km{
  font-weight: 700;
  font-size: 2em!important;
}

.driver-count li:nth-child(1), .driver-count li:nth-child(2){
  background: #E4CB0F!important;
}

.driver-count li:nth-child(3) span {
  padding-left: 5px;
}