
// For top view icon rotation on Google maps
.gm-style {
  img[src*="is_available=false"] {
    filter: saturate(10) hue-rotate(180deg) invert(40%)
  }

  img[src*="heading=1"] {
    transform: rotate(10deg);
  }
  img[src*="heading=2"] {
    transform: rotate(20deg);
  }
  img[src*="heading=3"] {
    transform: rotate(30deg);
  }
  img[src*="heading=4"] {
    transform: rotate(40deg);
  }
  img[src*="heading=5"] {
    transform: rotate(50deg);
  }
  img[src*="heading=6"] {
    transform: rotate(60deg);
  }
  img[src*="heading=7"] {
    transform: rotate(70deg);
  }
  img[src*="heading=8"] {
    transform: rotate(80deg);
  }
  img[src*="heading=9"] {
    transform: rotate(90deg);
  }
  img[src*="heading=10"] {
    transform: rotate(100deg);
  }
  img[src*="heading=11"] {
    transform: rotate(110deg);
  }
  img[src*="heading=12"] {
    transform: rotate(120deg);
  }
  img[src*="heading=13"] {
    transform: rotate(130deg);
  }
  img[src*="heading=14"] {
    transform: rotate(140deg);
  }
  img[src*="heading=15"] {
    transform: rotate(150deg);
  }
  img[src*="heading=16"] {
    transform: rotate(160deg);
  }
  img[src*="heading=17"] {
    transform: rotate(170deg);
  }
  img[src*="heading=18"] {
    transform: rotate(180deg);
  }
  img[src*="heading=19"] {
    transform: rotate(190deg);
  }
  img[src*="heading=20"] {
    transform: rotate(200deg);
  }
  img[src*="heading=21"] {
    transform: rotate(210deg);
  }
  img[src*="heading=22"] {
    transform: rotate(220deg);
  }
  img[src*="heading=23"] {
    transform: rotate(230deg);
  }
  img[src*="heading=24"] {
    transform: rotate(240deg);
  }
  img[src*="heading=25"] {
    transform: rotate(250deg);
  }
  img[src*="heading=26"] {
    transform: rotate(260deg);
  }
  img[src*="heading=27"] {
    transform: rotate(270deg);
  }
  img[src*="heading=28"] {
    transform: rotate(280deg);
  }
  img[src*="heading=29"] {
    transform: rotate(290deg);
  }
  img[src*="heading=30"] {
    transform: rotate(300deg);
  }
  img[src*="heading=31"] {
    transform: rotate(310deg);
  }
  img[src*="heading=32"] {
    transform: rotate(320deg);
  }
  img[src*="heading=33"] {
    transform: rotate(330deg);
  }
  img[src*="heading=34"] {
    transform: rotate(340deg);
  }
  img[src*="heading=35"] {
    transform: rotate(350deg);
  }
  img[src*="heading=36"] {
    transform: rotate(360deg);
  }
}