// Overwrite setting in main.css
.MultipleBookingLayout-Summary-BatchEZ {
  .MultipleBookingLayout-Summary-Group {
    .Left {
      .MultipleBookingLayout-Summary-Group-Detail {
        &:before {
          display: none;
        }

        .title {
          display: flex;
          padding-right: 0;
          background: none;
        }
      }
    }
  }
}

.MultipleBookingLayout-Summary-Group-Detail {
  line-height: 20px;
  &.long-content {
    h5 {
      padding-left: 10px;
    }
  }

  .title {
    position: relative;

    &:before {
      content: '';
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
      position: absolute;
      top: 13px;
      left: 0;
      right: 0;
      z-index: -1;
      background-repeat: repeat-x;
      height: 2px;
      background-size: 5px;
      background-position: 0px 0;
    }

    label > span {
      background-color: var(--color-white);
      font-size: 15px;
    }
  }
  
  .grow-2 {
    flex-grow: 2;
  }
}

.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h5.Batch-Booking-Summary__Vehicle {
  color: #444444
}
.Batch-Booking-Summary__Time {
  white-space: pre-wrap;
  text-align: right;
}