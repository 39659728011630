.optimize-route .Checkbox {
  margin-top: 6px !important;
  font-size: 15px !important;
}

.optimize-route .Checkbox input {
  margin: 0 10px 0 6px !important;
  display: inline-block;
}

.LocationContacts .block-sub-item .Radio {
  margin: 0px 10px 0 30px !important;
}

.LocationContacts .block-sub-item label {
  margin-top: -8px;
}
.PickupTime.block .block-item input[type="radio"] {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  margin: 0;
}
.PickupTime.block .block-item input[type="radio"]:hover {
  cursor:pointer
}

.InputOnfocus{
  border: 1px solid blue;
}
.block {
  display: block;
}
.PickupTime.block.error {
  border: 2px solid var(--color-red);
}
.emp-rsl-mapping-to {
  position: relative;
}
input.htr-employee-filter:focus {
  outline:none;
}
.Closure .Filter {
  align-items: flex-end;
}
ul.emp-rsl-wrapper {
  list-style: none;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 100;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid var(--color-gray-xxl);
  background-color: #fff;
  max-height: 140px;
  overflow-y: auto;
  box-shadow: 0 1px 10px rgba(0,0,0,.2);
  li {
    padding-left: 10px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid var(--color-gray-xxl);
    &:last-child {
      border-bottom: 0px;
    }
    &:hover {
      cursor: pointer;
      color: var(--color-green);
    }
  }
}
.employee-results-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 47px;
  right: 16px;
  height: 5px;
  background: white;
  z-index: 1112;
  border-radius: 5px 5px 0 0;
}
.employee-results-arrow::before, .employee-results-arrow::after {
  content: "";
  position: absolute;
  left: 18px;
  width: 10px;
  height: 10px;
  background:white;
  -webkit-transform:rotate(45deg);
  bottom: -16px;
  z-index: 1111;
}
.employee-results-arrow::before {
  box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.1);
  z-index: -1;
}
div.Popover-contact-list {
  div.batch-contact-filter-style {
    margin: 10px 10px 5px;
    i {
      left: 15px;
    }
    input {
      width: 100%;
      display: block;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
}
.Box.Modified-Popup {
  .Box-Icon {
    .Logo {
      padding: 0;
      .icon-modified-style {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
      }
    }
  }
  .Box-Content {
    a.modified-popup-url {
      text-decoration: underline;
      text-align: center;
      margin: 15px 0;
      width: 100%;
      display: inline-block;
      &:hover {
        color: #fff;
      }
    }
  }
}
div.cash-payor {
  a.cash-payor-radio {
    display: block;
    width: 100%;
    border: 1px solid transparent;
    color: transparent;
    // background: image-url('radio-btn.png') no-repeat center center;
    background-size: 20px 20px;
    &.uncheck {
      // background: image-url('radio-btn.png') no-repeat center center;
      background-size: 20px 20px;
    }
    &.checked {
      // background: image-url('checked-radio-btn.png') no-repeat center center;
      background-size: 20px 20px;
    }
  }
}
div.middle-screen-wrapper {
  padding: 30px 0;
  background-color: var(--color-gray-xxl);
  .default-wrapper{
    justify-content: center;
    min-width: 400px;
    flex: 1;
    &.draft-page-wrapper {
      border-left: 1px solid #ccc;
    }
    button, img {
      display: block;
    }
    button {
      margin: 100px auto 30px auto;
      width: 176px;
      font-weight: bold;
    }
    img {
      margin: 60px auto;
      max-width: 200px;
    }
  }
}
.MyDrivers .Modal-Basic .Box {
  max-width: 520px;
}
.MyDrivers .Modal-Basic .Box .Box-List-Item {
  padding-left: 0;
  padding-right: 0
}
.MyDrivers .Table tbody td.actions .Button {
  background-color: transparent !important;
  border: 0 none;
}
.option-visibility-icon {
  position: relative;
  left: 10px;
  top: 4px;
}
#Step-01, .Contacts, .card-step-1, .Add-Location.BatchUploadOptions, .BatchUploadValidate #Step-04, .Signup, .SignupBox {
  .Input-Flag {
    ::-webkit-input-placeholder {
       color:transparent;
    }

    :-moz-placeholder { /* Firefox 18- */
       color:transparent; 
    }

    ::-moz-placeholder {  /* Firefox 19+ */
       color:transparent; 
    }

    :-ms-input-placeholder {  
       color:transparent;
    }
  }
}
.Popover-Item-Driver-Item.Modal-Driver-Change-Date-Time.error {
  border: 2px solid var(--color-red);
  .Item-Driver-Show {
    border-radius: 0 0 var(--radius) var(--radius);
    padding: 5px 10px 10px;
    position: relative;
    width: 100%;
    margin: 20px -10px -10px;
  }
}
