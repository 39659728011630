.single-discount-code {
  .h1px {
    margin: 0 !important;
    height: 0 !important;
  }
  .discount-code {
    margin: 0 !important;
  }
  .discount-code-title {
    padding: 0;
  }
  .input-container {
    padding: 0;
  }
  .Overlay-Checkbox {
    padding-right: 10px;
  }
  .Input, .message {
    margin-bottom: 10px !important;
  }
}