body.webapp {
  .share-eta {
    background-color: #3fae29;
    text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
  }
  .share-eta__img {
      top: 50px;
      position: absolute;
      left: 15px;
      right: 15px;
  }
  .share-eta__img img {
    max-width: 100%;
    display: block;
    margin: auto;
  }
  .share-eta__content {
    position: absolute;
    left: 15px;
    right: 15px;
    margin: auto;
    height: 120px;
    top: 0;
    bottom: 0;
  }
  .share-eta__table {
      display: table;
      width: 100%;
      height: 100%;
  }
  .share-eta__table div {
      display: table-cell;
      vertical-align: middle;
  }
  .share-eta__content p {
      font-size: 20px;
      color: white;
  }
  .share-eta__link {
      bottom: 50px;
      position: absolute;
      left: 15px;
      right: 15px;
  }
  .share-eta__link img {
      width: 64px;
      height: auto;
      margin: 0 8px;
  }
  .share-eta__link--custom img {
    width: auto;
    max-width: 300px;
    height: 70px;
  }
  .share-eta__button {  max-width: 200px; margin: auto; padding-top: 15px; display: block !important;}
  .share-eta__button a { line-height: 40px;  color: #3fae29; display: block; background-color: white; padding: 0 25px;  text-decoration: none;  border-radius: 6px; font-size: 20px;}
  .share-eta__button a:hover {  background-color: #0e730f;  color: white;}
}