$xs-small-screen:  320px;
$small-screen:     480px;
$medium-screen:    768px;
$large-screen:     992px;
$xs-large-screen:  1200px;

// Define main color
$colorWhite: #FFFFFF;
$colorGreen: #40b450;
$colorBlue: #2b3e50;
$colorBlueLink: #00adf5;
$colorRedLink: #f44336;
$colorGreenButton: #3fae29;
$colorGrey: #6d6d6d;
$colorbgGrey: #eaeaea;
// $colorbgGrey: #bababa;
$colorBlack: #444444;


$colorGreenLight: #3eb549;
$colorGreenDark: #41A94F;
$colorLightGreen: #80F200;
$colorGray: #cccccc;
$colorYellow: #F7DB1E;
$colorYellowLight: #F5E100;
$colorYellowDark: #FED040;
$colorOrange: #f37021;
$colorGrayLight: #f4f4f4;
$colorGrayDark: #808080;
$colorGrayDarkMore: #707070;
$colorRed: #CE9E9E;
$colorRedLight: #F34133;
$colorRedLighter: #F12515;
$colorBlackSmoke: rgba(0,0,0,0.05);
$colorBlack: #343434;
$colorBlackDark: #000000;
// Font
$fontOpenSans: "Open Sans", sans-serif;
$fontHelveticaNeue: 'Helvetica Neue', Arial, Helvetica, sans-serif;


// Custom mixins
@mixin utility-responsive($width) {
  @media only screen and (min-width: $width) { @content; }
}

@mixin utility-responsive-range($min, $max) {
  @media all and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
// @include responsive(wide-screens) { width: 80%; }

@mixin custom-placeholder($color) {
  &::-webkit-input-placeholder { color: $color; }
       &:-ms-input-placeholder { color: $color;  }
           &::-moz-placeholder { /* Firefox 19+ */ color: $color;  }
            &:-moz-placeholder { /* Firefox 18- */ color: $color;  }
}

@mixin custom-retinize($file, $type, $width, $height) {
  background-image: image-url('' + $file + '.' + $type);

  @media (-webkit-min-device-pixel-ratio: 1.5),
         (min--moz-device-pixel-ratio: 1.5),
         (-o-min-device-pixel-ratio: 3/2),
         (min-device-pixel-ratio: 1.5),
         (min-resolution: 1.5dppx) {
    & {
      background-image: image-url('' + $file + '.' + $type);
      -webkit-background-size: $width $height;
         -moz-background-size: $width $height;
              background-size: $width $height;
    }
  }
}

// how to use @include retinize('icon-dribbble', 'png', 24px, 24px);

@mixin custom-background-body($file, $type) {
    background: image-url($file + '.' + $type) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

// how to use @include retinize('icon-dribbble', 'png', 24px, 24px);
@mixin custom-rounded($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}
@mixin custom-shadow($x, $y, $z, $blur, $color) {
  -webkit-box-shadow: $x $y $z $blur $color;
     -moz-box-shadow: $x $y $z $blur $color;
          box-shadow: $x $y $z $blur $color;
}
@mixin custom-shadow-inset($x, $y, $z, $blur, $color) {
  -webkit-box-shadow: inset $x $y $z $blur $color;
     -moz-box-shadow: inset $x $y $z $blur $color;
          box-shadow: inset $x $y $z $blur $color;
}
@mixin custom-transition($property) {
  -webkit-transition: $property .5s ease;
     -moz-transition: $property .5s ease;
       -o-transition: $property .5s ease;
          transition: $property .5s ease;
}
@mixin custom-box-sizing {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
@mixin custom-linear-gradient($from, $to) {
  /* Fallback for sad browsers */
  background-color: $to;
  /* Mozilla Firefox */
  background-image:-moz-linear-gradient($from, $to);
  /* Opera */
  background-image:-o-linear-gradient($from, $to);
  /* WebKit (Chrome 11+) */
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to));
  /* WebKit (Safari 5.1+, Chrome 10+) */
  background-image: -webkit-linear-gradient($from, $to);
  /* IE10 */
  background-image: -ms-linear-gradient($from, $to);
  /* W3C */
  background-image: linear-gradient($from, $to);
}

@mixin text-highlight-selection($background, $color) {
  ::selection { background: $background; color: $color; text-shadow: none; }
  ::-webkit-selection{ background: $background; color: $color; text-shadow: none; }
  ::-moz-selection{ background: $background; color: $color; text-shadow: none; }
}

@mixin bootstrap-column($name, $width) {
  .col-xs-#{$name}, .col-sm-#{$name}, .col-md-#{$name}, .col-lg-#{$name} {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-xs-#{$name} {
    width: $width;
    float: left;
  }

  /*Define extended grid system for bootstrap*/
  @media (min-width: 768px) {
    .col-sm-#{$name} {
      width: $width;
      float: left;
    }
  }
  @media (min-width: 992px) {
    .col-md-#{$name} {
      width: $width;
      float: left;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-#{$name} {
      width: $width;
      float: left;
    }
  }
}

