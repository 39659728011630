
// overwrite .BookingWizard .Content .block
.BookingWizard {
  .Content {
    .tracking-shopping.block {
      margin: 15px 0px 0px 0px;
      padding: 0px;

      .Dropzone {
        margin: 0px;
        border: none;
      }
    }
  }
}

.tracking-shopping-wrapper {
  .tracking-shopping,
  .price-summary {
    display: none;
  }

  &.view-price-summary {
    .price-summary {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &.view-shopping-list {
    .tracking-shopping {
      display: flex;
      flex-direction: column;
    }
    .tracking-shopping:not(.collapse) {
      height: 100%;
    }
  }
}

.tracking-shopping {
  --color-gray: #a1a1a1;
  --color-gray-s: #e4f8e7;
  --color-green-s: #dadada;
  --color-green-l: #0e730f;
  --color-black-s: #4a4a4a;
  --outer-radius: 6px;
  // minus 1px of border
  --inner-radius: 5px; 
  
  // for locked state
  &.locked {
    .lock-status {
      background-color: #dbdbdb;
    }

    .shopping-item {
      .shopping-quantity div {
        border-color: var(--color-border-gray);
      }
      .shopping-quantity-subtract,
      .shopping-quantity-add {
        background-color: var(--color-background-gray);
        cursor: default;
      }

      .photo-attachment-block {
        .Icon,
        .add-photo-text {
          color: var(--color-gray);
        }
      }
    }

  }

  .shopping-item {
    margin-bottom: 15px;
  }

  // Override margin of photo attachment
  .Attachments.block .block-item .Dropzone {
    margin: 0px;
  }

  &.Radius-Default {
    border-radius: var(--radius);
  }

  .Radius-Top-Default {
    border-radius: var(--radius) var(--radius) 0 0;
  }

  .Radius-Bottom-Default {
    border-radius: 0 0 var(--radius) var(--radius);
  }


  .Radius-Top-Default-Inner {
    border-radius: var(--inner-radius) var(--inner-radius) 0 0;
  }

  .Radius-Bottom-Default-Inner {
    border-radius: 0 0 var(--inner-radius) var(--inner-radius);
  }

  background-color: var(--color-white);
  font-size: 15px;

  .grow-3 {
    flex-grow: 3;
  }

  .tracking-title-bar {
    align-items: center;
  }

  .lock-status {
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 2px 4px;
    background-color: #ffdb00;
  }

  .tracking-title-text {
    flex-grow: 3;
    font-weight: 500;
  }

  .tracking-collapse-button {
    align-self: center;
  }

  &.collapse {
    .tracking-collapse-button {
      img {
        transform: rotate(180deg);
      }
    }

    .tracking-shopping-list-wrapper {
      display: none;
    }

    .tracking-shopping-summary {
      display: none;
    }
  }

  .tracking-item-status-summary {
    background-color: var(--color-gray-s);
    font-size: 14px;
    color: var(--color-black-s);
  }

  .tracking-inline-message {
    background-color: #f8aba4;
    font-size: 13px;
    z-index: 1;
  }

  .tracking-shopping-summary {
    position: relative;
    z-index: 1;
    border-top: 1px solid #dbdbdb;
    background-color: #f9f9f9;

    .total-line {
      position: relative;
      font-size: 18px;

      > label {
        flex: 1;

        span {
          background-color: #f9f9f9;
        }
      }

      > div {
        background-color: #f9f9f9;
      }

      &:before {
        content: '';
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        z-index: -1;
        background-repeat: repeat-x;
        height: 2px;
        background-size: 5px;
        background-position: 0px 0;
      }
    }

    .item-breakdown {
      color: var(--color-green);
      align-self: flex-end;
      font-size: 13px;
      align-items: center;
    }
  }

  .shopping-list-message {
    color: var(--color-gray);
  }
}

.tracking-shopping-list-container {
  position: absolute;
  width: 350px;
  right: 17px;
  top: 17px;
  bottom: 17px;
}

.price-summary {
  --color-gray: #a1a1a1;

  &.Radius-Default {
    border-radius: var(--radius);
  }

  .Radius-Top-Default {
    border-radius: var(--radius) var(--radius) 0 0;
  }

  .Radius-Bottom-Default {
    border-radius: 0 0 var(--radius) var(--radius);
  }

  background-color: var(--color-white);
  flex: 1;

  .back-button {
    transform: rotate(-90deg);
  }

  .title-bar {
    border-bottom: 1px solid #c1c1c1;
    font-size: 15px;
  }

  .price-summary-detail-title {
    font-size: 14px;
  }

  .grow-2 {
    flex-grow: 2;
  }

  .grow-3 {
    flex-grow: 3;
  }

  .price-summary-detail-list {
    font-size: 13px;
    color: #282828
  }

  .detail-list-item {
    .cost-per-item {
      font-style: italic;
      color: var(--color-gray);
    }

    .description {
      width: 50%;
    }

    .price-quantity {
      position: relative;

      &:before {
        content: '';
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        z-index: 0;
        background-repeat: repeat-x;
        height: 2px;
        background-size: 5px;
        background-position: 0px 0;
      }

      > label {
        z-index: 2;

        > span {
          background-color: var(--color-white);
        }
      }
    }
  }


  .bottom-price-summary {
    background-color: #f9f9f9;
    border-top: 1px solid #c1c1c1;

    .total-line {
      position: relative;
      font-size: 18px;

      > label {
        z-index: 1;
        flex: 1;
  
        span {
          background-color: var(--color-white, #ffffff);
        }
      }
  
      > div {
        z-index: 1;
        background-color: var(--color-white, #ffffff);
      }  

      &:before {
        content: '';
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        z-index: 0;
        background-repeat: repeat-x;
        height: 2px;
        background-size: 5px;
        background-position: 0px 0;
      }
    }
  }

  .group-of-items {
    flex: 1;
    background-color: var(--color-white);
  }

  .shopping-bag,
  .discount {
    font-size: 13px;
    color: #282828;
  }

  .discount {
    position: relative;

    &:before {
      content: '';
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
      position: absolute;
      bottom: 21px;
      left: 0;
      right: 0;
      z-index: 0;
      background-repeat: repeat-x;
      height: 2px;
      background-size: 5px;
      background-position: 0px 0;
    }

    > label {
      z-index: 2;

      > span {
        background-color: var(--color-white);
      }
    }
  }
}
