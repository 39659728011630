.toastr-wrapper {
  position: fixed;
  top: 75px;
  width: 440px;
  height: 50px;
  background-color: var(--color-yellow);
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 10;

  & > img {
    width: 28px;
    height: 30px;
    margin-right: 5px;
  }

  p {
    font-size: var(--font-size-s);
    color: var(--color-gray-1);
    margin: 0;
  }

  .close-icon {
    margin-right: 10px;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
    }
  }
}