.export-box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: var(--height);
    font-size: var(--font-size);
    background-color: var(--color-white);
    margin-right: 20px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    min-width: 111px;

    .dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 10px;
        min-width: 160px;
        width: max-content;
        padding: 8px;
        font-size: var(--font-size);
        background-color: var(--color-white);
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        z-index: 5;

        li {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 8px;
            border-bottom: 1px solid #dbdbdb;

            img {
                padding: 5px;
                width: 20px;
                height: 20px;
                filter: brightness(0.5);
            }
        }

        li:last-child {
            border-bottom: 0;
        }
    }

    .dropdown::before {
        content: '';
        position: absolute;
        top: -5px;
        right: 28px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 5px solid var(--color-white);
        z-index: 3;
    }

    .dropdown::after {
        content: '';
        position: absolute;
        top: -6px;
        right: 28px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 5px solid var(--color-gray-xl);
        z-index: 2;
    }
}

.export-box .dropdown li:hover,
.export-box:hover {
    background-color: #f2f2f2;
}