// style for tally bar on new booking page
.tally-bar {
  --color-green-s: #0e730f;
  --color-black-s: #4a4a4a;
  --color-yellow: #ffdd00;


  justify-content: space-evenly;
  background-color: rgba(14, 115, 15, 0.5);

  &.Radius-Default {
    border-radius: var(--radius);
  }

  .tally-bar-row-item {
    width: 100%;
    align-items: center;
    border-right: 0.5px solid var(--color-white);
    text-align: center;
    
    &:last-child {
      border-right: none;
    }

    .value {
      color: var(--color-white);
      font-size: 15px;
      font-weight: 500;
      line-height: 15px;
    }

    .text {
      color: var(--color-yellow);
      font-size: 13px;
      line-height: 13px;
    }
  }

}