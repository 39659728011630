
.BatchReimBursementLayout {
  padding-bottom: 20px;
}
.Batch-Reimbursement {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px !important;
  .Batch-Reimbursement-Table {
    > table {
      border: 0 none;
      padding-right: 20px;
    }
    .Table-Content {
      color: #282828;
      font-size: 13px;
      .reimbursement-all__header td {
        border-top: 1px solid #e1e1e1;
        background-color: #7c7c7c;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        padding: 5px;
        height: unset;
      }
      .reimbursement-all__free td {
        padding: 5px;
        font-size: 11px;
        color: #666666
      }
      .reimbursement-all__line td {
        background-color: white;
      }
      .reimbursement-all__cell {
        height: 64px;
        box-sizing: border-box;
        padding: 10px;
        &--location {
          padding :5px 15px;
          font-size: 12px;
        }
      }
      td:first-child {
        border-left: 1px solid #e1e1e1;
      }
      td:last-child {
        border-right: 1px solid #e1e1e1;
      }
      tr:last-child td {
        border-bottom: 1px solid #e1e1e1;
      }

      .batch-confirm__id {
        font-size: 11px;
        font-weight: 400;
        margin-left: 20px;
      }
      .batch-confirm__reimbursement {
        display: flex;
        justify-content: center;
      }
      .batch-confirm__reimbursement {
        >div:first-of-type {
          width: 44px;
          height: 44px;
          background-color: var(--color-white);
          position: relative;
          margin-right: 15px;
          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .grid-cell__free {
          flex: 1;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .reimbursement-all__checkbox {
        position: relative;
        height: 20px;
        margin-top: 5px;
        input[type=checkbox]:checked+label:before {
          background-color: var(--color-red);
          border-color: var(--color-red);
        }
        input {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%!important;
          height: 100%!important;
          margin: 0;
          z-index: 2;
          cursor: pointer;
        }
        label {
          padding-left: 28px;
          font-size: 13px;
          display: block;
          white-space: nowrap;
          line-height: 22px;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border: 1px solid #a1a1a1;
            border-radius: 3px;
          }
        }
        i {
          color: white !important;
          display: block;
          font-size: 22px;
          position: absolute;
          top: 0;
          left: 0;
          line-height: 20px;
          text-align: center;
          width: 20px;
        }
      }
      .reimbursement-all__summary td {
        height: unset;
        padding: 8px 5px;
        box-sizing: border-box;
        background-color: #e7e7e7;
      }
      .reimbursement-all_total {
        background-color: #e7e7e7;
      }
      .reimbursement-total__block {
        div:first-child {
          font-size: 12px;
        }
      }
      
    }    
    .Table-Reimbursement-ID {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    .Table-Reimbursement-Name {
      width: 380px;
      min-width: 380px;
      max-width: 380px;
    }
    .Table-Reimbursement-Reimburse {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
    }
  }
}

.batch-confirm {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow: auto;
  .table {
    width: max-content;
  }
}
.batch-confirm__body {
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px !important;
    height: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0e730f !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #dcf4d0 !important;
  }
}
.batch-confirm__footer {
  
}
.batch-confirm__footer {
  padding: 20px 15px 15px 15px;
}
.batch-confirm__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-size: 11px;
  padding-right: 10px;
  color: #282828;
}
.batch-confirm__right {
  text-align: right;
  align-items: center;
}
.batch-confirm__total{
  font-size: 12px;
  margin-bottom: 10px;
  color: #282828;
  b {
    font-size: 16px;
  }
}
.batch-confirm__action {
  button {
    min-width: 180px;
    font-size: 15px;
    font-weight: bold;
  }
  button:first-child {
    margin-right: 10px;
  }
}

.line-through {
  text-decoration-line: line-through;
}

.bgr-red {
  background-color: var(--color-red) !important;
}

.m-note {
  margin: 15px 0 !important;
}

.reimbursement__timer {
  background-color: #ffdb00 !important;
}

.reimbursement__subtotal {
  border-left: none !important;
  text-align: center !important;
  .reimbursement__fees {
    font-weight: bold;
  }
}