body.webapp {
  .booking-tracking { position: relative;
    #location-driver-image {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      margin-top: -130px;
      margin-left: -130px;
      transition: top 0.3s;
    }
    .share-bottom-panel {
      position: absolute; bottom: 14px !important; left: 0; z-index: 1; width: 100% !important;
      &.small {
        font-size: 10px;
        > .grid {
          margin-left: 5px; margin-right: 5px;
          .column { font-size: 13px; }
        }
      }
    }
    .share-bottom-panel, .share-top-panel {
      > .grid > .column {
        &.title {
          padding-top: 5px; padding-bottom: 5px; background: #fddc03;
          h3 { color: #0f0d00; font-weight: 300; }
          &.darker { background: #e0c002; }
        }
        &.content {
          background: #00a200;
          margin-bottom: 14px;
          a {
            color: $colorWhite;
            &:hover, &:focus { color: $colorWhite; text-decoration: underline; }
          }
          .mgt { margin-top: 10px; }
          .column.small {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            min-height: auto !important;
            line-height: 18px !important;
            &:last-child { margin-bottom: 10px; }
          }
          &:not(:first-child) { border-left: 1px solid #63bf63; }
          > a img.logo { width:75%; max-width: 230px; margin-top: 5px; }
          > .grid > .column {
            color: $colorWhite;
            &:not(:first-child) { border-left: 1px solid #63bf63; }
            > .grid > .column {
              .column {
                padding-top: 5px;
                padding-bottom: 5px;
                min-height: 28px;
              }
              .static-react-rater {
                direction: rtl;
                .fa { font-size: 0.7rem; color: $colorWhite; }
              }
            }
            .ui.tiny.image.square { height: 65px; width: 65px; }
            img.vehicle-icon {
              filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
              height: 40px;
            }
          }
          &:last-child {
            .app-link {
              img {
                width: 40%;
                &.detected {
                  width: 80%;
                  &.small { width: 40%; min-width: 100px; }
                }
              }
              + .app-link { margin-left: 10px; }
            }
          }
        }
      }
    }
    .share-top-panel {
      position: absolute; top: 0; left: 0; z-index: 1; width: 100% !important;
      h3 {
        font-weight: 300;
        margin: 0px;
      }
      > .grid > .column {
        &.content {
          margin-top: 38px;
          margin-bottom: 0px;
          color: $colorWhite;
        }
      }
      &.small {
        > .grid > .column {
          &.content {
            margin-top: 28px;
            .ui.tiny.image.square {
              position: absolute;
              top: 11px;
              right: 40px;
              width: 30px;
              height: 30px;
              filter: brightness(0) invert(1);
              border-radius: 0px;
            }
          }
        }
      }
    }
    #bottom-panel {
      #panel-buttons { position: absolute; top: -15px; right: 0px;
        > div:not(:last-child) { margin-right: 5px; }
      }
      #cancel-booking-modal { position: initial; display: inline-block;
        &.scrolling.dimmable.dimmed { overflow: visible; }
      }
      #cancel-button, #review-details-button {
        margin: 0px;
        // &.absolute {position: absolute; right: 0; top: -10px; }
      }
      #thanks-you-modal {
        img { height: 70px; margin-bottom: 5px; }
        .title { font-size: 1.5em; color: $colorGreen; }
      }
      #review-modal {
        h3 { margin: 0px; font-weight: 300; }
        img.driver-avatar { width: 72px; height: 72px; margin-bottom: 10px;}
        .total-fee { font-size: 30px; margin-top: 10px; text-align: right; font-weight: 300; }
        label.square-checkbox{
          .checkbox-title{
            width: 80%;
          }
          input[type="checkbox"] {
            &:checked + .checkbox-title + span.square{
              // background: image-url('web_assets/img_checkbox_button_checked.png') no-repeat top left;
              background-size: 100% 100%;
            }
            &:checked + .checkbox-title{
              color: $colorGreen;
            }
          }
        }
        .block-rater {
          position: relative;
          .react-rater {
            font-size: 30px;
            label{
              width: 24px;
              height: 24px;
              background: url('../../images/icon-v1/star-unactive-icon.png');
              background-repeat: no-repeat;
              background-size: 24px 24px;
              cursor: pointer;
            }
            .is-active{
              background: image-url("../../images/icon-v1/star-actived-icon.png") !important;
              background-repeat: no-repeat !important;
              background-size: 24px 24px !important;
            }
            .is-disabled{
              background: url('../../images/icon-v1/star-unactive-icon.png');
              background-repeat: no-repeat;
              background-size: 24px 24px;
            }
          }
        }
        .note {
          width: 100%;
          border: none;
          resize: none;
          outline: none;
          height: 65px;
          transition: border 0.3s;
          background-color: #f4f4f4;
          color: gray;
          padding: 5px 5px 5px 10px;
          margin-top: 10px;
        }
        .preference-driver-actions{
          padding-bottom: 5px;
          text-align: left;
          > .button {
            width: 49%;
            margin: 0px;
            &:last-child { float: right; }
          }
        }
        .non-actions{
          padding: 1rem 1rem;
        }
        .text-center{
          text-align: center;
        }
        .reasons{
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .checkbox{
          margin-top: 0px;
          margin-bottom: 10px;
        }
      }
      .reasons-modal {
        h2.header {
          img { width: 72px; }
          .sub.header { color: $colorWhite; }
          .content { padding: 0.5em; }
        }
        > .content {
          padding: 1em 1.5em;
          .button {
            background: $colorWhite;
            border-radius: 0px;
            border: 1px solid $colorGray;
            font-weight: 300;
            color: $colorBlack;
            &:hover {
              border-color: #99D0A0;
              box-shadow: 0px 0px 1px $colorGreen;
            }
            &:focus, &:active, &.active {
              background-color: $colorGreen !important;
              border-color: $colorGreen !important;
              color: $colorWhite !important;
              opacity: 1 !important;
            }
          }
        }
      }
      .black { color: $colorBlackDark; }
      .gray { color: $colorGrayDark; }
      .all-info {
        .ui.segment { border-radius: 0px; box-shadow: none; background-color: transparent;
          #toggle-bottom-info{
            padding: 0px;
            i {
              color: white;
              background-color: #40b450;
              border-radius: 5px 5px 0px 0px;
              width: 50px;
              height: 28px;
              font-size: 2em;
              cursor: pointer;
              margin: 0px;
            }
          }
        }
        .title-block {
          border-bottom: 2px solid $colorYellowDark;
          background-color: $colorWhite;
          .column { padding: 0.75em;
            .green { color: $colorGreen; }
            h2.header {
              font-weight: 300;
              color: $colorBlack;
              padding: 0px;
              background-color: transparent;
              font-size: 1.4em;
              line-height: 1.7em;
            }
          }
        }
        .content-block {
          background-color: $colorWhite;
          .booking-info .column { padding-bottom: 0.5em; }
          .time-type { color: $colorGreen; font-size: 1.1em; }
          .pickup-time { margin-left: 10px; }
          .total-fee { font-weight: 600; font-size: 1.1em; }
          .locations { padding-top: 0px;
            .ui.list {
              max-height: 120px;
              overflow: hidden;
              overflow-y: auto;
              .item {
                padding: 0.3em 0px;
                position: relative;
                padding-right: 25px;
                .status { position: absolute; right: 5px; top: 10px;
                  img { height: 12px; }
                }
                .image { margin-right: 10px; width: 30px; height: 30px; }
                border-bottom: 1px solid $colorGray;
                &:first-child { border-top: 1px solid $colorGray; }
              }
            }
          }
          .links {
            padding-top: 0px;
            // text-align: center;
            .item {
              .image.active { display: none; }
              .header { font-weight: 300; }
              &:hover {
                .header { color: $colorGreen; }
                .image:not(.active) { display: none; }
                .image.active { display: inline-block; }
              }
            }
          }
          .driver-info {
            &.just-full {
              background-color: $colorGrayLight;
              padding: 1em;
              .ui.list { border-right: 1px solid $colorGray;
                .icon img { max-height: 22px; }
              }
            }
            &.just-minimize {
              .image.tiny { width: 45px;
                &.square { height: 45px; }
              }
            }
            .ui.list {
              .list { padding-top: 0px; }
              .static-react-rater {
                direction: rtl;
                .fa { font-size: 1.2rem; color: $colorGreen; }
              }
            }
            .button img { height: 20px; }
          }
        }
      }
    }
    .booking-info{
      padding-bottom: 10px;
      .title{
        font-weight: bold;
      }
      .ui.list {
        padding-top: 10px;
        .item {
          .title {
            width: 150px; display: inline-block;
            + .ui.grid {
              margin: 5px -1rem 0px;
              .column {
                padding: 0px 1rem;
              }
            }
          }
          .content { display: inline-block; }
        }
      }
    }
    #infobox_duration {
      position: absolute;
      top: 14%;
      text-align: center;
      width: 58px;
      .number {
        font-weight: bold;
        font-size: 17px;
      }
    }
    .infobox_offline_driver {
      position: absolute;
      top: 24%;
      text-align: center;
      width: 61px;
    }
    .infobox_duration_custom {
      position: absolute;
      top: 14%;
      text-align: center;
      width: 58px;
      .number {
        font-weight: bold;
        font-size: 17px;
      }
    }
    .dlvr-no-signal {
      position: absolute;
      width: 35px;
      height: auto;
      left: 12px;
      top: 14px;
    }
    
    .invisible {
      visibility: hidden!important;
    }    
  }
}