#loading-modal {
  #progress-loading {
    width: 100%;
    background: #02751a;
  }
  #bar-percent {
    width: 0;
    max-width: 220px;
    height: 4px;
    border-radius: 4px;
    background-color: #ffdb00;
    transition: width 0.15s;
  }
  .progress-loading-container {
    height: 4px;
    width: 220px;
    position: absolute;
    top: calc(50% + 85px);
    border-radius: 4px;
    visibility: hidden;
  }
  .progress-loading-container-visible {
    visibility: visible
  }
}
