// style for summary part inside booking summary

.tally-summary {
  --color-green-s: #edf7e9;
  font-size: 14px;
  color: #444444;

  .grow-2 {
    flex-grow: 2;
  }

  .Radius-Top-Default {
    border-radius: var(--radius) var(--radius) 0 0;
  }
  
  .Radius-Bottom-Default {
    border-radius: 0 0 var(--radius) var(--radius);
  }

  .tally-summary__service-type-time-type {
    line-height: 14px;
  }
  
  .tally-summary__service-type-time-type {
    img {
      max-width: 70px;
      max-height: 32px;
      min-height: 0px;
      align-self: flex-end;
    }
  }
  
  .tally-summary__pickup-and-distance {
    background-color: var(--color-white);
  }

  .tally-summary__estimated-time-arrival {
    background-color: var(--color-green-s);
  }

  .tally-summary__pickup div:first-child,
  .tally-summary__distance div:first-child {
    flex-grow: 2;
  }

  .tally-summary__estimated-time-arrival {
    .icon-wrapper {
      display: block;
      flex-grow: 2;
      align-self: center;
      color: #a1a1a1;
    }

    .Icon {
      vertical-align: middle;
    }
  }

  .tally-summary__estimated-time-arrival div {
    align-self: center;
  }

  .tally-summary__estimated-time-arrival div:last-child {
    text-align: right;
  }

  & > div:last-child {
    border-radius: 0 0 var(--radius) var(--radius);
  }

  .tally-summary__estimated-time-arrival__time {
    white-space: pre-wrap;
  }
}
