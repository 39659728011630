.batch-select-header {
  background-color: var(--color-gray-xxxl);
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 30px;

  h2 {
    margin: 0;
    font-size: 24px;
    color: #444;
  }
}

.batch-select-wrapper {
  display: flex;
  height: calc(100vh - 110px);
  align-items: center;
  justify-content: space-evenly;
}

.batch-item {
  border: 1px solid var(--color-light-gray);
  border-radius: 6px;
  text-align: center;
  flex: 1;
  width: 400px;
  height: 468px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &:hover {
    border-color: var(--color-background-green);
  }
}

.batch-img {
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  img {
    width: 150px;
    height: 150px;
  }
}

.large-img {
  img {
    width: 100%;
    height: 100%;
  }
}

.batch-content {
  padding: 40px 30px;
  background-color: var(--color-gray-xxxl);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  flex: 2;

  h2 {
    margin: 0 0 20px;
    font-size: 26px;
    color: #282828;
  }
}

.batch-desc {
  color: var(--color-background-gray);
  margin: 0;
  font-weight: 500;
  font-size: 17px;
}

.batch-btn {
  padding: 11px 0;
  border: 1px solid var(--color-border-gray);
  border-radius: 5px;
  font-weight: 500;
  background-color: var(--color-white);
  pointer-events: auto;
  position: absolute;
  bottom: 30px;
  width: 86%;
  box-sizing: border-box;
  font-size: 18px;

  &:hover {
    background-color: var(--color-green);
    border-color: var(--color-green);
    color: var(--color-white);
    cursor: pointer;
  }
}

.batch-center {
  justify-content: center;

  .batch-item-wrapper:first-child {
    margin-right: 40px;
  }
}

@media (max-width: 1366px) {
  .batch-btn {
    bottom: 30px;
  }
}