.cashback-empty-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;

  img {
    width: 50px;
    height: 50px;
  }

  p {
    color: var(--color-gray-d);
    width: 380px;
    text-align: center;
  }

  .btn-wrapper {
    display : flex;
    margin-top: 20px;

    a {
      display: block;
      padding: 10px 0;
      text-align: center;
      width: 215px;
      border-radius: 6px;
      cursor: pointer;
    }

    .new-booking {
      color: var(--color-white);
      border: 1px solid var(--color-green);
      background-color: var(--color-green);
    }
  }
}