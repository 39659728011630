.number-driver {
  position: absolute;
  right: 381px;
  display: flex;
  z-index: 2;

  .curved-div {
    background: transparent;
    color: #FFF;
    text-align: center;
    z-index: 2;
  }

  .number-driver__content {
    background-color: #fedb00;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    height: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-shadow:  0 1px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .number-driver__id{
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #666666;
  }
  .number-driver__id span:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
  .number-driver__name {
    font-size: 12px;
    color: #666666;
    margin-top: 2px;
  }
  @media (min-width: 1024px) and (max-width: 1366px) {
    right: 457.2px;
  }
}