.card-container {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  img {
    border-radius: 6px;
  }
  .new-tag {
    padding: 3px 6px 2px;
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    font-size: 11px;
    font-weight: 500;
    color: #282828;
    background: #ffdb00;
    margin-left: 10px;
    text-transform: uppercase;
  }
  &:hover {
    background-color: #3fae29;
    p:first-child {
      color: #fff;
    }
    p:last-child {
      color: rgb(224, 224, 224);
    }
  }
  > div {
    margin-left: 10px;
  }
  p:first-child {
    margin-top: 12px;
    margin-bottom: 5px;
    color: #282828;
    font-size: 18px;
    font-weight: 500;
  }
  p:last-child {
    font-size: 14px;
    color: #666;
    margin: 0;
  }
}
