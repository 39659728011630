$react-rater-link: #fff !default;
$react-rater-hover: #FBD71F !default;
$react-rater-active: #FBD71F !default;

.react-rater {
    // based on kejun's gist
    // http://dabblet.com/gist/4352050
    // same as transform: scale(-1, 1) but has better compatibility
    direction: rtl;
    unicode-bidi: bidi-override;
    text-align: left;
    display: inline-block;
    a {
        text-decoration: none;
        cursor: pointer;
        color: $react-rater-link !important;
        &:hover {
            color: $react-rater-hover !important;
            ~ a {
                color: $react-rater-hover !important;
            }
        }
        &.is-active {
            color: $react-rater-active !important;
            ~ a {
                color: $react-rater-active;
            }
        }
        &.is-disabled {
            color: $react-rater-link !important;
            cursor: not-allowed;
        }
    }
}

$react-rater-link: #ccc !default;
$react-rater-hover: #666 !default;
$react-rater-active: #fc6 !default;

.static-react-rater {
    // based on kejun's gist
    // http://dabblet.com/gist/4352050
    // same as transform: scale(-1, 1) but has better compatibility
    direction: rtl;
    unicode-bidi: bidi-override;
    text-align: left;
    display: inline-block;
    a {
        text-decoration: none;
        cursor: pointer;
        color: $react-rater-link;
        &.is-active {
            color: $react-rater-active !important;
            ~ a {
                color: $react-rater-active;
            }
        }
    }
}

