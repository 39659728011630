.dropdown-wrapper {
  position: relative;

  .dropdown-content {
    width: 100%;
    box-sizing: border-box;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #dbdbdb;
    z-index: 10;
    top: 47px;
  }

  .dropdown-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -7px;
    right: 50%;
    transform: translateX(50%);
    z-index: 2;
  }

  .dropdown-arrow-background {
    border-bottom: 8 solid rgb(219, 219, 219);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    position: absolute;
    top: -8px;
    right: 50%;
    transform: translateX(50%);
    z-index: 1;
  }

  .Icon {
    margin-left: 5px;
  }
}