.Popup-Container {
  border-radius: 8px;
  background-color: #3fae29;
  position: relative;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 14px;
  width: 100%;
  max-width: 725px;
  min-width: 300px;
}

.Popup-Header {
  font-weight: bold;
  font-size: 17px;
  color: white;
  padding: 20px;
}

.Popup-Close {
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
}

.Popup-Close i {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.Popup-Close i:hover {
  color: #dbdbdb;
}

.Popup-Body {
  margin: 0 10px 10px 10px;
  padding: 15px;
  border-radius: 4px;
  background-color: #fff;
}

.Title-Error {
  color: var(--color-red);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
    font-size: 18px;
  }
}

.Confirm-Ltl {
  .Popup-Body {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  .popup-ltl__text {
    font-size: 22px;
    font-weight: bold;
    color: #444444;
    margin-top: 15px;
  }
  .popup-ltl__action {
    text-align: center;
    margin: 30px 0px 20px 0px;
    font-size: 20px;
    font-weight: 400;
    .Button {
      padding: 0 20px;
      min-width: 140px;
      height: 48px;
      font-size: 20px;
      font-weight: 500;
      &.transparent {
        border: 1px solid var(--color-green);
        color: var(--color-dark-green);
        &:hover{
          background: var(--color-green);
          color: #fff;
        }
      }
    }
  }
  .popup-ltl__footer {
    font-size: 12px;
    margin-bottom: 20px;
    color: #a1a1a1;
  }
}
.cancel-booking__popup {
  .Popup-Body {
    color: #444444
  }
  .cancel-booking__message{
    border-radius: 4px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
  }
  .cancel-booking__chat {
    margin: 0 20px 0 40px;
    button {
      padding: 0 50px 0 50px;
      height: 36px;
    }
  }
  .text-red {
    color: #f44336
  }
  .cancel-booking__multiple {
    margin-top: 10px;
    font-size: 12px;
    color: #a1a1a1
  }
  .cancel-booking__action {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .Button {
      padding: 0 20px;
      min-width: 140px;
      height: 48px;
      font-size: 20px;
      font-weight: 500;
      &.transparent {
        border: 1px solid var(--color-green);
        color: var(--color-dark-green);
        &:hover{
          background: var(--color-green);
          color: #fff;
        }
      }
      &:disabled {
        opacity: .6;
        cursor: not-allowed;
      }
    }
  }
  .cancel-booking__footer {
    font-size: 12px;
    color: #a1a1a1;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
    cursor: pointer;
    a {
      color: var(--color-green);
      font-weight: 500;
    }
  }
  .cancel-booking__reason {
    display: flex;
    width: calc(100% / 3);
    margin-bottom: 20px;
    padding-right: 10px;
    box-sizing: border-box;
    align-items: center;
    >div:first-child {
      display: flex;
      align-self: baseline;
    }
    >div:last-child {
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;
      max-width: 85%;
    }
  }
  
  //custom radiobox
  .Overlay-RadioBox-Ltl {
    position: relative;
    height: 20px;
    margin-right: 8px;
  }

  .Overlay-RadioBox-Ltl input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 2;
    cursor: pointer;
  }

  .Overlay-RadioBox-Ltl .RadioBox {
    padding-left: 20px;
    line-height: 20px;
    display: block;
    white-space: nowrap;
    color: #ffffff;
  }

  .Overlay-RadioBox-Ltl .RadioBox:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #a9a9a9;
    border-radius: 50%;
    box-sizing: unset;
  }

  .Overlay-RadioBox-Ltl .RadioBox.disabled:before {
    background-color: #ccc;
  }

  .Overlay-RadioBox-Ltl input[type='radio']:checked + .RadioBox:before {
    background-color: #ffffff;
    border-color: var(--color-green);
  }

  .Overlay-RadioBox-Ltl input[type='radio']:checked + .RadioBox span {
    display: block;
    background-color: var(--color-green);
  }

  .Overlay-RadioBox-Ltl .RadioBox span {
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
    color: var(--color-green);
    line-height: 12px;
    text-align: center;
    width: 12px;
    height: 12px;
    font-size: 0 !important;
    border-radius: 50%;
  }
}
