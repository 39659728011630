.message-popup {
  padding: 10px 20px;
  display: flex;
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  z-index: 100002;

  &-blue {
    background-color: #00adf5;
  }

  &-green {
    background-color: #3fae29;
  }

  &-purple {
    background-color: #7521d3;
  }

  &-brown {
    background-color: var(--color-yellow);
  }

  img {
    width: 24px;
    margin-right: 15px
  }

  .message-content {
    font-size: 16px;
    font-weight: 500;
    color: #373a3c;
    margin: 0;
  }
}