// .green-border.green-text.Button { text-decoration: none; }
// .PageHead-tabs .item {
//   text-decoration: none;
//   color: initial;
//   &.selected {
//     background: var(--color-white);
//     color: var(--color-green);
//     border-top-left-radius: var(--radius-s);
//     border-top-right-radius: var(--radius-s);
//     box-shadow: 0 -1px 2px rgba(0,0,0,.1);
//   }
// }
// .Table td.actions .Button,
// .PageHead-actions .Button {
//   text-decoration: none;
// }
// .MyDrivers .Modal .Popup .Button,
// .table .Box-Actions .Button {
//   text-decoration: none;
//   color: initial;
// }
// .Filter .FormGroup label { text-transform: uppercase; }
// .RightMenu .Box-List-Item {
//   color: initial;
// }
// .Input.disabled {
//   input { pointer-events:none; }
// }
.pointer {
  cursor: pointer;
}
.EzSpreadSheetFormGroup {
  width: 50%;
  display: block;
  box-sizing: border-box;
  div.FormGroup {
    width: 100%;
  }
}
.ItemAddingBlock {
  display: block;
}
.rtl {
  direction: rtl;
  text-align: left;
}
#infobox_duration {
  position: absolute;
  top: 14%;
  text-align: center;
  width: 58px;
  .number {
    font-weight: bold;
    font-size: 17px;
  }
}
.infobox_offline_driver {
  position: absolute;
  top: 24%;
  text-align: center;
  width: 61px;
}

.infobox_duration_custom {
  position: absolute;
  top: 14%;
  text-align: center;
  width: 58px;
  color: white;
  .number {
    font-weight: bold;
    font-size: 17px;
  }
}

.Services.block.list-view .block-item .Radio + label {
  -webkit-box-flex: none;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}

.block-item-action {
  position: relative;
}

.block-item-action select {
  min-width: 60px;
}

.disabled {
  pointer-events: none;
}


// for service type Popup eye
.modal-ServicePopupEye *{
  white-space: pre-line !important;
  text-align: center !important;
}

.Checkbox.single-line {height: auto;}


.DatePicker-Calendar-img>div img {
  max-width: 100%;
}

.BookingWizard .ContentUnsetPosition {
  position: unset !important;
}

.ui-state-active, .ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active, a.ui-button:active,
.ui-button:active, .ui-state-active.ui-button:hover
{
  border: none;
  background: #f9f9f9;
}

.Dropzone {
  &.is-dragover {
    background: #f5f5f5 !important;
    border-style: solid !important;
  }
}
.no_driver_notification {
  padding: 40px 0;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}

.alert-danger {
  color: #a94442;
  background-color: white;
  border-color: white;
}

.error-border { border: 2px solid #ff0000; }

div.Batch-Custom td {
  &.align-td-center {
    text-align: center;
  }
  &.photos_and_files {
    max-width: 100px;
    min-width: 100px;
    text-align: center;
  }
}

a.show-photo-popup-btn, label.upload-batch-photos {
  color: #7c7c7c;
  display: flex;
  justify-content: center;
  align-items: center;
  i.material-icons {
    color: #7c7c7c;
    font-size: 17px;
    margin-right: 5px;
    &.updated-attachment{
      color: var(--color-green);
    }
  }
  &:hover {
    color: var(--color-green);
    cursor: pointer;
    i.material-icons {
      color: var(--color-green);
    }
  }
}
.Batch-Custom .Table thead td {
  &.photos_and_files {
    max-width: 100px;
    min-width: 100px;
  }
}

div#batch-booking-attachments-popup {
  div.Box {
    &.Attachements-Box {
      height: auto;
      width: 25%;
      .Attachments {
        width: 100%;
      }
    }
  }
}

.emp-search {
  width: 185px !important;
}
#search_fuzzy_search{
  min-width: 313px;
}
.word-break {
  word-break: break-word;
}
.MultipleBookingLayout .BookingWizard .Locations.block .block-item.actions .Button.custom-button-destination {
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
  text-align: right;
  max-width: 150px;
}
.Batch {
  .BatchUpload-Title {
    &.description-area {
      padding: 0 20px 15px;
    }
    h3.Black-Gray-text {
      color: #444;
      font-size: 15px;
      font-weight: normal;
    }
    .Button.Black-Gray-text i {
      font-size: 15px;
    }
  }
  .Batch-Custom {
    .batch-icon-status {
      margin-left: 5px;
    }
  }
}
.BatchUpload-Edit-Title {
  max-width: 100%;
  &.Black-Gray-text input {
    border-bottom: 1px solid #444;
    color: #444;
    font-size: 15px;
    font-weight: normal;
    height: 20px;
  }
  input {
    max-width: 100% !important;
  }
}
.Booking {
  .MultipleBookingLayout {
    flex-wrap: wrap;
    .Batch {
      width: 100%;
      .BatchUpload-Title {
        padding: 0 0 15px;
      }
    }
    .MultipleBookingLayout-Area {
      display: flex;
      height: calc(100vh - 245px);
    }
  }
}
.right-text {
  text-align: right;
}
.share-location-modal-content, .button-share-modal {
  .Box.non-background {
    background: none;
  }
  .Share-Location-Submit {
    span {
      color: #fff;
      font-size: 15px;
    }
  }
}
.Action-Buttons {
  margin-top: 10px;
  display: flex;
  button {
    width: calc(50% - 5px);
    font-size: 15px;
    color: #3fae29;
    &.close-btn {
      margin-right: 10px;
    }
    &.close-modal-common.customize-btn {
      position: unset;
      padding: 9px;
      margin-right: 10px;
      color: #fff;
    }
  }
}
.MyDrivers{
  .Filter {
    .Input {
      width: 300px;
    }
  }
}
.Selection-Per-Location {
  &.Popover {
    top: 0;
    bottom: 0;
    border-radius: 0!important;
    padding: 0!important;
    .Popover-Item {
      color: #fff;
      display: flex;
      align-items: center;
      .Pin {
        margin-right: 10px;
      }
      label {
        display: flex;
        font-size: var(--font-size-s);
        flex: 1
      }
    }
    .Popover-List {
      margin-bottom: 56px;
      padding: 10px!important;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
  .Location-Item {
    .Border-Bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .block-item-action {
        margin-bottom: 5px;
      }
      .Popover-Item {
        margin-right: 10px;
      }
    }
  }
}
button.add-location-btn {
  display: flex;
  flex-wrap: nowrap;
  height: auto;
  min-height: 44px;
  span {
    width: 65px;
    text-align: left;
  }
}

.optional-note-input {
  display: flex;
  align-items: flex-start;

  img {
    margin: 0 10px 0 15px;
  }
}

.dlvr-no-signal {
  position: absolute;
  width: 35px;
  height: auto;
  left: 12px;
  top: 14px;
}

.invisible {
  visibility: hidden!important;
}