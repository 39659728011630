.linear-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-out;
  background: linear-gradient(to right, rgba(12,0,0,0.15) 0%, rgba(255,255,255,0.2) 20%, rgba(12,0,0,0.15) 30%);
  background-size: 1000px 104px;
  width: var(--width);
  height: var(--height);
  margin-top: 10px;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0;
  }
  100%{
    background-position: 468px 0;
  }
}
