body.webapp {
  .booking-review {
    position: relative;
    .title { color: $colorGrayDark; margin-bottom: 5px; }
    h5 { margin-bottom: 10px;  font-weight: 300; }
    .locations-block {
      border: 1px solid $colorGray;
      border-width: 1px 0px 1px;
      position: relative;
      padding: 1em 0px;
      margin: 1em 0px;
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $colorWhite;
        top: -4px;
        left: 35px;
        box-shadow: -1px -1px 0 0 #bababc;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 2;
      }
      > .ui.list > .item {
        > .content { max-width: 85%; }
        &:not(:last-child) {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 11px;
            width: 1px;
            border-left: 1px solid $colorGray;
            height: 95%;
          }
        }
      }
      .location-infos {
        padding: 3px 0px 15px;
        .fa {
          font-size: 1.2em;
          color: $colorGrayDark;
          width: 30px;
        }
        img { margin-right: 8px; }
      }
    }
    .pickup-time-block {
      margin-top: 1em;
      .time-type {
        font-size: 1.2em;
        color: $colorGreen;
        text-transform: uppercase;
        margin-right: 10px;
      }
    }
    .general-infos-block {
      .vehicle-infos {
        margin-top: 0px;
        .image.tiny { width: auto; height: 20px; }
      }
    }
    .driver-infos-block {
      margin-top: 1em;
      background-color: $colorGrayLight;
      border-top: 1px solid $colorGray;
      position: relative;
      padding: 1em;
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $colorGrayLight;
        top: -4px;
        left: 35px;
        box-shadow: -1px -1px 0 0 #bababc;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 2;
      }
      .ui.list {
        .content .header { font-size: 1.2em; font-weight: 300; }
        .list { padding-top: 5px; }
      }
      .driver-avatar { width: 50px; height: 50px; }
      .icon.star { color: $colorGreen; }
    }
    table.fee-table {
      border: none;
      background-color: $colorGrayLight;
      padding: 2em;
      margin-top: 0px;
      // background: image-url("background/line_bg.png") repeat-y top center;
      background-size: 100%;
      @include custom-rounded(0px);
      position: relative;
      .before {
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
      .after {
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      td:not(:first-child) { text-align: right; }
      > tbody {
        > tr {
          td:first-child { padding-left: 0px; }
          td:last-child {
            padding-right: 0px;
            -webkit-box-shadow: none !important;
               -moz-box-shadow: none !important;
                    box-shadow: none !important;
          }
          &.last td { border-top: 1px solid $colorGray; font-size: 1.5em;
            &:last-child { color: $colorGreen; }
          }
          &.group-title {
            > td { font-weight: 300;  }
            + tr > td { border-top: none; padding-left: 1em; }
          }
          > td:not(:only-child):first-child { font-weight: 300; }
        }
      }
      tbody {
        tr:last-child, tr.last  {
          border-bottom: none !important;
          box-shadow: none !important;
        }
        tr.text {
          td {
            border: none;
            color: $colorGrayDark;
            font-weight: 300;
          }
        }
      }
      table {
        margin: -0.7em 0;
        border: none !important;
      }
    }
  }
}