.cashback-bar-wrapper {
  overflow: hidden;
  &.is-show {
    overflow: unset;
    z-index: 10002;
  }

  .tally-bar-container {
    margin: 0;
  }
}

.SingleBookingLayout {
  .cashback-bar-inner {
    padding: 0 5px;
    margin: 0 5px;
    .items-wrapper {
      margin: 15px 10px 0;
    }
  }
}

.cashback-bar-inner {
  transition: transform 0.5s cubic-bezier(.34, .68, .57, .87);
  transform: translateX(0);

  .items-wrapper {
    background-color: #f1f1f1;
    border-radius: 5px;
    height: 38px;
    overflow: hidden;
  }

  .hide-cashback-bar {
    height: 0!important;
    margin: 0!important;
  }

  .cashback-bar {
    flex: 1;
    & .slide-up {
      transform: translateY(-38px);
      transition: transform 0.5s cubic-bezier(.34, .68, .57, .87);
    }
  }

  .tally-bar {
    margin-top: 10px;
  }

  &.show-tally {
    transform: translateX(-50%);
  }
}

.cashback-item {
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  p {
    margin: 0;
    font-size: 14px;
    color: #282828;
  }

  img {
    margin-right: 6px;
  }
}
