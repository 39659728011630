.frontpage-floating {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 3;
  height: calc(100% - 20px);
}
.front-page-wrapper {
  height: 100%;
  width: 548px;
  overflow-y: auto;
  box-sizing: border-box;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f1f1f1;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.12), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  &::-webkit-scrollbar-track {
    background-color: #d7edd7;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #0e730f;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0e730f;
    border-radius: 0;
    outline: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0e730f;
  }
  .feature-project {
    padding: 20px 20px 10px 20px;
    background-image: linear-gradient(to bottom, rgba(64, 174, 41, 0.1), rgba(2, 117, 26, 0.1));
  }
  .btn-see-all {
    min-width: 57px;
    height: 24px;
    padding: 0 5px;
    border-radius: 3px;
    border: solid 1px #a1a1a1;
    background-color: #f9f9f9;
    span {
      opacity: 0.8;
      font-size: 12px;
      color: #282828;
    }
  }
  .back-to-stop {
    padding: 10px 20px;
    border-radius: 18px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    border: solid 1px #d8d8d8;
    background-color: #fff;
    font-size: 14px;
    color: #888;
    box-sizing: border-box;
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
  .front-page__title {
    font-size: 16px;
    font-weight: 500;
    color: #282828;
  }
  .status-spil {
    p {
      font-weight: bold;
    }
    img {
      width: auto !important;
      height: auto !important;
    }
  }
  .front-page__content {
    white-space: pre-line;
    font-size: 14px;
    color: #888888;
  }
  .front-page__no-login {
    margin: 30px 0;
    width: 500px;
    text-align: center;
    p {
      font-size: 16px;
      white-space: pre-line;
    }
    .front-page__button-container {
      margin-bottom: 30px;
      width: 320px;
      height: 40px;
      border-radius: 5px;
      border: solid 1px #a1a1a1;
      background-color: #fff;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        cursor: pointer;
        height: 100%;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: #282828;
        line-height: 40px;
      }
    }
  }
  .front-page__no-booking {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    p {
      font-size: 16px;
      margin-top: 10px;
      white-space: pre-line;
    }
  }
  .front-page__list-booking {
    justify-content: space-between;
    display: flex;
    margin: 30px 20px 10px 20px;
  }
  .front-page__support {
    margin-left: 20px;
    margin-right: 20px;
    > div {
      width: 240px;
      height: 110px;
      padding: 15px;
      border-radius: 6px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      box-sizing: border-box;
      > div {
        display: flex;
        img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
        p {
          margin: 0;
        }
        > div {
          margin-bottom: 4px;
        }
      }
      p {
        margin: 0;
        margin-top: 4px;
      }
      button {
        width: 210px;
        height: 30px;
        margin: 10px 0 0;
        border-radius: 3px;
        border: solid 1px #a1a1a1;
        background-color: #f9f9f9;
        span {
          font-size: 12px;
          opacity: 0.8;
          color: #282828;
        }
      }
    }
    .front-page__full {
      width: 100%;
      height: 70px;
      padding: 0 15px;
      border-radius: 6px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        flex-direction: column;
        align-self: center;
        > div {
          display: flex;
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          p {
            margin: 0;
          }
        }
      }
      button {
        width: 90px;
        margin-top: 0;
      }
    }
  }
  .front-page__create {
    box-sizing: border-box;
    height: 110px;
    margin: 20px 20px 0 20px;
    padding: 0 0 0 15px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    background-color: #fff;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    background-position-x: 360px;
    p:first-child {
      margin-top: 15px;
      margin-bottom: 5px;
    }
    p:last-child {
      margin: 0;
    }
    > div {
      margin: 10px 0 15px 0;
      button {
        height: 30px;
        border-radius: 3px;
        border: solid 1px #a1a1a1;
        background-color: #f9f9f9;
        span {
          font-size: 12px;
          opacity: 0.8;
          color: #282828;
        }
      }
      .batch {
        min-width: 80px;
        padding: 0 5px;
        margin-right: 10px;
      }
      .batch-ez {
        width: 100px;
        margin-right: 10px;
      }
      .batch-smart {
        width: 160px;
      }
    }
  }
  .front-page__more {
    margin: 30px 20px 0 20px;
    padding-bottom: 30px;
    > p {
      margin: 0;
    }
    > img {
      width: 500px;
      height: 320px;
      margin: 10px 0 20px;
      border-radius: 6px;
      border: solid 1px #d8d8d8;
      box-sizing: border-box;
    }
    > div {
      > div:first-child {
        width: 240px;
        height: 283px;
        margin-right: 20px;
        border-radius: 6px;
        background-color: #fff;
        border: solid 1px #d8d8d8;
        img {
          width: 240px;
          height: 148px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        div {
          margin: 15px 10px;
          p:last-child {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
      > div:last-child {
        width: 240px;
        height: 283px;
        margin-right: 20px;
        border-radius: 6px;
        background-color: #fff;
        border: solid 1px #d8d8d8;
        img {
          width: 240px;
          height: 148px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        div {
          margin: 15px 10px;
          p:first-child {
            margin-top: 15px;
            margin-bottom: 10px;
          }
          p:last-child {
            margin-top: 0;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
