.custom{
  .bordered{
    table {
      border-collapse: collapse;
      width: 100%;
      padding: 10px;
      td, th {
        border: 1px solid #ccc;
        padding: 10px;
      }
      th{
        text-align: center;
      }
    }
  }
}