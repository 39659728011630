#landing-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  &:before { position: absolute; z-index: -1; content: "";
    // @include custom-background-body('background-preload3', 'png');
    height: 100%; width: 100%;
  }

  .desc_text{
    color: #fff;
    font-size: 12pt;
    text-align: center;
  }

  .logo {
    margin-top: 100px;
  }
  .download-buttons {
    margin-top: 40px;
    a { color: #fff; font-weight: 400; }
    .download-button {
      position: relative;
      display: inline-block;
      width: 120px;
      height: 48px;
      margin: 5px;
      padding: 10px 11px;
      border-radius: 5px;
      vertical-align: middle;
      text-align: left;
      font-weight: 300;
      background-color: #00bf8f;
      .icon { position: absolute; right: 11px; top: 0; font-size: 32px; }
      .download-text {
        display: block;
        font-size: 11px;
        letter-spacing: .01em;
        line-height: 1em;
      }
      .client-name {
        display: block;
        margin-top: 4px;
        font-size: 14px;
        letter-spacing: normal;
      }
    }
  }
  .container{
    height: 90%;
  }

}
.ui_tab_content{
  margin-left: 20px;
  margin-bottom: 20px;
}
#footer{
  height: 10%;    
  text-align: center;
  color: #fff;
  .static_link{
    width: 100%;
    a{
      cursor: pointer;
    }
  }
  
}
