@import "default";

html { overflow-x: hidden; }
body { overflow-x: hidden; }
.clickable{
 cursor: pointer;
}
#client-page {
  font-family: $fontHelveticaNeue;
  .opacity-transition-enter { opacity: 0.01; transition: all 1s ease-in; }
  .opacity-transition-enter.opacity-transition-enter-active { opacity: 1; }
  .opacity-transition-leave { opacity: 1; transition: all 1s ease-in; }
  .opacity-transition-leave.opacity-transition-leave-active { opacity: 0.01; }

  .well { background-color: $colorWhite; }
  .new-booking-actions {
    padding-bottom: 15px;
    z-index: 300;
    .back { background-color: $colorWhite; color: $colorGreenLight; cursor: pointer;
      display: block; height: 50px; line-height: 50px;
      border: 1px solid $colorGreenLight;
    }
    .next { background-color: $colorGreenLight; color: $colorWhite; cursor: pointer;
      display: block; height: 50px; line-height: 50px;
    }
  }
  .new-booking {
    position: relative;
    z-index: 300;
    padding: 10px 15px 15px 15px;
    .well {
      margin-bottom: 0px;
    }
    &.limited{
      overflow: hidden;
      overflow-y: auto;
    }
    hr.green { display: block; height: 1px; border: 0; border-top: 1px solid $colorGreenLight; margin: 1em 0; padding: 0; }
    hr.gray { display: block; height: 1px; border: 0; border-top: 1px solid $colorGrayDark; margin: 1em 0; padding: 0; }
    .header { margin: 0px; }
  }
  .new-booking-steps {
    position: relative;
    z-index: 300;
    margin-bottom: 0px;
    padding: 0;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    .step1, .step2, .step3 {
      padding: 11px 25px;
      &:not(.active) {
        .content > .title { color: $colorGray; }
      }
      &.active { background-color: $colorGreenLight;
        &:after { background-color: $colorGreenLight; }
        .content > .title { color: $colorWhite; padding-left: 10px; }
      }
    }
    .step1.active {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        border-top: 20px solid whitesmoke;
        border-bottom: 20px solid whitesmoke;
        border-left: 20px solid $colorGreenLight;
      }
    }
    .step2.active {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-top: 20px solid $colorGreenLight;
        border-bottom: 20px solid $colorGreenLight;
        border-left: 20px solid whitesmoke;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        border-top: 20px solid whitesmoke;
        border-bottom: 20px solid whitesmoke;
        border-left: 20px solid $colorGreenLight;
      }
    }
    .step3.active {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-top: 20px solid $colorGreenLight;
        border-bottom: 20px solid $colorGreenLight;
        border-left: 20px solid whitesmoke;
      }
    }
  }
  .new-booking{
    .time-control { cursor: pointer;
      display: inline-block; text-align: center; margin-right: 30px;
      // border: 1px solid transparent;
      font-weight: bold;
      text-transform: uppercase;
      // width: 150px; height: 30px; line-height: 30px;
      // color: $colorGreenLight;
      &.active {
        // color: $colorWhite;
        // background-color: $colorGreenLight;
        // border: 1px solid $colorGreenLight;
        // border-radius: 20px;
        span.circle{
          // background: image-url('web_assets/img_radio_button_checked.png') no-repeat top left;
          background-size: 100% 100%;
        }
      }
      span.circle {
        display:inline-block;
        width:19px;
        height:19px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        cursor:pointer;
        -moz-border-radius:  50%;
        border-radius:  50%;
        // background: image-url('web_assets/img_radio_button_unchecked.png') no-repeat top left;
        background-size: 100% 100%;
        margin-right: 20px;
        -webkit-transition:background-color 0.4s linear;
        -o-transition:background-color 0.4s linear;
        -moz-transition:background-color 0.4s linear;
        transition:background-color 0.4s linear;
      }
    }
    input.datetimepicker { background-color: $colorGrayLight; margin-top: 15px; margin-bottom: 15px; border: none; }

    .new-booking-location {
      min-height: 40px;
      background-color: $colorWhite;
      border-bottom: 1px solid $colorGrayLight;
      border-top: 0px;
      position: relative;
      &.add-location-button{margin-bottom: 20px;}
      .left-component { position: absolute; margin-top: -5px; margin-left: 0px; z-index: 10;
        &.add-location { cursor: pointer; margin-top: 14px; margin-left: 8px; color: $colorGrayDark; }
      }
      span.left-component.add-location {margin-top: 10px; margin-left: 30px}

      .right-component { color: $colorGray; cursor: pointer; z-index: 1; position: absolute; top: 0; right: 0; margin-top: 12px; }

      .current-location { @include custom-transition(all); opacity: 0; margin-top: 12px; position: absolute; top: -5px; right: 35px; z-index: 1; cursor: pointer; }
      &:hover {
        .current-location { opacity: 1; }
      }

      .column { padding: 0; }
      .selectize-input { border: none; background: none; box-shadow: none;
        input { margin-left: 40px !important; width: 80% !important; }
        .item { margin-left: 40px !important; width: 80%; overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap; }
        &:after { content: none; }
      }
      input.autocomplete {
        margin: 0 2px 0 40px;
        width: 80%;
        display: inline-block;
        padding: 8px;
        min-height: 0;
        max-height: none;
        max-width: 100%;
        text-indent: 0;
        border: 0 none;
        background: none;
        line-height: inherit;
        -webkit-user-select: auto;
        box-shadow: none;
        color: #303030;
        font-family: inherit;
        font-size: 13px;
        -webkit-font-smoothing: inherit;
        &:focus {
          outline: none;
        }
      }

      .selectize-dropdown { z-index: 20; }
      .selectize-dropdown-content { color: $colorGrayDark;
        .option{
          &.active { background-color: $colorGrayLight; }
          &:nth-child(odd) { border: 1px solid $colorGrayLight; }
        }
      }
    }
    .new-booking-location-subpanel {
      // position: absolute;
      // width: 100%;
      // margin: 0 -15px;
      // bottom: -60px;
      a {
        display: block;
        width: 100%;
        text-decoration: none;
        background-color: $colorGreenLight;
        color: $colorWhite;
        border: none;
        padding: 15px;
      }
    }
  }
  .new-booking-customize {
    // @media screen and (min-height: 800px) { height: 700px; }
    .time-control { cursor: pointer;
      display: inline-block; text-align: center; margin-right: 30px;
      border: 1px solid transparent;
      width: 150px; height: 30px; line-height: 30px;
      color: $colorGreenLight;
      &.active {
        color: $colorWhite;
        background-color: $colorGreenLight;
        border: 1px solid $colorGreenLight;
        border-radius: 20px;
      }
    }
    input.datetimepicker { background-color: $colorGrayLight; margin-top: 15px; margin-bottom: 30px; width: 230px; border: none; }
    .extra-requirements {
      small { color: $colorGrayDark; }
      .field:first-child { padding-left: 7px; }
      .select-box { display: inline-block; margin-left: 10px; }
      .extra-info{ color: $colorGreen; }
      .extra-help{
        margin-top: 10px; float: right; cursor: pointer;
        + label.square-checkbox{
          margin-right: 20px;
        }
      }
    }
    .note { background-color: $colorGrayLight; border: none; resize: none; margin-top: 10px; margin-bottom: 10px; padding: 10px; height: 100px; }
    .coupon { background-color: $colorGrayLight; border: none; resize: none; margin-top: 10px; margin-bottom: 10px; }
    .image {
      img {
        width:100%;
      }
    }
  }

  .booking-detail-header { margin-bottom: 15px;
    h3 { color: $colorWhite; display: inline-block; margin-right: 20px; }
    a { background-color: $colorWhite; color: $colorGreenLight; cursor: pointer;
      display: inline-block; height: 40px; line-height: 40px; width: 150px;
      text-align: center;
    }
  }
  .new-booking-review {
    .history-content {
      .time-header { margin-top: 10px; margin-bottom: 20px;
        .header { padding: 10px; border: 1px solid $colorGreenLight; border-radius: 20px;
          display: inline-block; width: 150px; text-align: center; margin: 0px;
        }
        .time { margin-left: 30px; color: $colorGrayDark; }
      }
      .location-review {
        .location-review-container {
          .line {
            border-left: 2px solid $colorGray; height: 145px; position: absolute; top: 0; left: 8%; top: 8%;
          }
          .is-payer{
            img{ height: 14px; }
            span.text{ padding-left: 10px; }
          }
        }
      }
    }
    // border-radius: 0px; height: 550px; overflow-y: scroll;
    // .content {
    .pickup-info{
      line-height: 30px;
      margin-top: 10px;
      .type { text-transform: uppercase; font-weight: bold; font-size: 20px; }
      .time { color: $colorGrayDark; text-align: right; }
    }
    .location-review {
      h3.title { margin: 0px; font-weight: 400; font-size: 16px; }
      .location-review-container {
        &:not(:first-child){ margin-top: 10px; }
        p { margin: 10px 0px 0px 0px; color: $colorGrayDark; }
        i.fa { margin-right: 10px; color: $colorGrayDark; }
        input:not([type="radio"]) {
          display: inline-block;
          // border: none;
          // background-color: $colorGrayLight;
          margin-top: 10px;
          width: 200px;
          // box-shadow: none;
        }
        .radio{
          &.left-labeled{
            img{ height: 14px; }
            label{
              position: relative;
              padding-left: 10px;
              input[type="radio"]{
                margin-left: 0px;
                right: -20px;
                top: 0px;
              }
            }
          }
        }
        .line {
          border-left: 2px solid $colorGray; height: 180px; position: absolute; top: 0; left: 6.3%; top: 8%;
        }
      }
    }
    .preference {
      .control-label{ text-align: left; }
      p { color: $colorGrayDark; margin-top: 7px; }
    }
    // }
    .sidebar {
      background-color: $colorGrayLight;
      padding: 10px 5px 0px;
      .extra-requirements {
        // margin-top: 10px;
        .title { font-weight: normal;
          &.discount { color: $colorGreenLight; font-weight: 700; }
          // &.after-hours-service { color: $colorOrange; }
          &.total-fee{ font-weight: 700; }
          small { padding-left: 10px; }
        }
        .fee { font-weight: 300; }
        .fee-total { font-weight: 300; font-size: 44px; margin-top: -2px; color: $colorGreenLight; }
        .info { color: $colorGrayDark; margin: 20px 0px 10px; }
        .fee-distance { font-weight: 300; }
      }
    }
    .pictures {
      margin-bottom: 20px;
      img {
        border: 1px solid $colorGrayLight;
      }
    }
  }
  .my-deliveries {
    .title { z-index: 300;
      .breadcrumb { padding-top: 0px; background-color: transparent;
        & > li + li:before {
          content: "";
        }
        li { width: 33.3%;
          a { color: #626262; width: 100%; text-align: center; cursor: pointer;
            h3 { display: inline-block; margin: 0px; width: 100%;
              border-bottom: 2px solid #626262;
            }
          }
          &.active {
            a { color: $colorWhite;
              h3 { border-bottom: 2px solid $colorWhite; }
            }
          }
        }
      }
    }
    .content { z-index: 300; height: 500px; overflow-y: scroll;
      .history-enter { opacity: 0; transition: all 1s ease-in; }
      .history-enter.history-enter-active { opacity: 1; }
      .history-leave { opacity: 1; transition: all 1s ease-in; }
      .history-leave.history-leave-active { opacity: 0; }
      .gray { color: $colorGrayDark; }
      .well { border-radius: 0px; padding: 0px 15px 0px 15px;
        &:last-child { margin-bottom: 0px; }
      }
      .left-panel { border-right: 1px solid $colorGray; padding: 15px;
        .time-type {
          display: inline-block; color: $colorGreenLight;
          margin-right: 20px;
        }
        .gray { color: $colorGrayDark; }
        .space { margin: 0px 20px 0px 20px; }
        .route { margin-top: 10px; line-height: 26px;
          .name {
            display: inline-block;
            margin-left: 10px; width: 80%; overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap;
          }
          .status {
            color: $colorGreenLight;
            i { margin-left: 10px; }
          }
        }
      }
      .right-panel { padding: 15px; }
      .blue-bg {
        font-weight: 300;
        color: $colorWhite;
        background-color: #84BBDF;
      }
      .green-bg {
        font-weight: 300;
        color: $colorWhite;
        background-color: $colorGreenLight;
      }
      .gray-bg {
        font-weight: 300;
        color: $colorWhite;
        background-color: $colorGrayDark;
      }
    }
  }
  .control-panel {
    background-color: $colorWhite;
    &.business {
      background-color: #333333;
      .main-menu {
        ul {
          margin: 0; padding: 0;
          list-style: none;
          li {
            margin: 0; padding: 30px 0 15px 0;
            transition: background 0.2s;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              top: 0%;
              left: 0px;
              width: 100%;
              background: -webkit-linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0.05) 100%);
              background: linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0.05) 100%);
              height: 1px;
            }
            &:hover {
              background: rgba(0, 0, 0, 0.2);
            }
            a {
              h4 {
                font-size: 14px;
                font-weight: 400;
              }
              img { width: 40px; height: 40px; }
            }
          }
        }
      }
      a { color: $colorWhite;
        &:hover { text-decoration: none; color: $colorWhite; }
        h4 { margin: 10px; }
        &.inverse{
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .terms_conditions_text {
        a:hover { text-decoration: underline; }
      }
    }
    a { color: #000;
      &:hover { color: $colorGreenLight; }
      h4 { margin: 10px; }
      &.inverse{
        text-decoration: underline;
        color: $colorGreenLight;
        &:hover {
          text-decoration: underline;
          color: $colorGreenLight;
        }
      }
    }
    .logo { background-color: $colorGreenLight;
      padding: 16px 0;
    }
    .content { margin-top: 20px;
      .column { margin-bottom: 30px; }
    }
    .help { width:100%; position: absolute; bottom: 0%; left: 0%; }
    .terms_conditions_text {
      a {
        color: $colorGreen;
        h4 {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .main-panel { position: absolute; top: 15px; left: 0; }
  .user { position: absolute; top: 0%; right: 0%; margin: 12px; padding: 6px; z-index: 500; border-radius: 10px;
    .profile-image { border-radius: 12px; cursor: pointer; }
  }

  .current-location-button {
    position: absolute; bottom: 90px; right: 0%; margin-right: 11px; z-index: 299;
    .btn {
      padding: 5px 7px;
      border-radius: 3px;
      outline: none;
    }
  }

  /* Custom grid bootstrap */
  @include bootstrap-column('1-custom', 11.3333333333%);
  @include bootstrap-column('11-custom', 88.6666666667%);

  /* Main menu */
  .main-menu {
    ul {
      margin: 0; padding: 0;
      list-style: none;
      li {
        margin: 0; padding: 30px 0 15px 0;
        a {
          &:hover { text-decoration: none; }
          h4 {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .welcome-message { z-index: 300; }
  .tracking { position: relative;
    .tracking-status { z-index: 300;
      .status { width: 30%; margin: 0 auto;
        h3 { display: inline; margin: 10px; }
        i { font-size: 24px; }
      }
    }
    .bottom-panel { position: absolute; left: 0%; bottom: 0%; z-index: 300;
      .well { margin: 0; }
      .utility {
        li { background-color: $colorGreenLight; padding: 10px; margin: 5px; }
        a { cursor: pointer; color: $colorWhite; text-decoration: none; }
      }
      .left-panel { border-right: 2px solid $colorGray;
        .time-type {
          display: inline-block; color: $colorGreenLight;
          margin-right: 20px;
        }
        .gray { color: $colorGrayDark; }
        .space { margin: 0px 20px 0px 20px; }
        .route { margin-top: 10px; line-height: 26px;
          .name {
            display: inline-block;
            margin-left: 10px; width: 80%; overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap;
          }
          .status {
            color: $colorGreenLight;
            i { margin-left: 10px; }
          }
        }
      }
      .right-panel {
        .cancel-booking { position: absolute; bottom: 0%; right: 2%; color: $colorGreenLight; cursor: pointer; }
      }
    }
  }
}

.driver-details {
  // .col-md-5,.col-md-4 {padding: 0 5px;}
  .description { font-weight: 300; color: $colorGrayDark; }
  .rating { font-size: 24px; }
  .driver-phone-and-license-number { margin-top: 24px; color: $colorGrayDark;
    i { margin-right: 5px; }
  }
  // .driver-image { width: 100%; }
  .driver-image { max-width: 100px; min-width: 80px; max-height: 100px; min-height: 80px; }
}

.map-location-modal {
  .modal-content { border: none !important; border-radius: 0px !important; }
}

.modal-backdrop { width: 100%; height: 100%; z-index: 1024; }
.info-modal { margin-top: 10%;
  .modal-content { border: none !important; border-radius: 0px !important;
    .button-panel { margin-top: 30px;
      button { width: 150px; border: 1px solid $colorGreen; border-radius: 0px; margin: 0px 10px 0px 10px; outline: none; }
      .yes { color: $colorGreenLight; }
      .no { color: $colorWhite; background-color: $colorGreenLight; }
    }
  }
}
.cancel-booking-modal { margin-top: 10%;
  .modal-content { border: none !important; border-radius: 0px !important;
    .description { margin-top: 30px; color: $colorGrayDark; }
    .button-panel { margin-top: 30px;
      button { width: 150px; border: 1px solid $colorGreen; border-radius: 0px; margin: 0px 10px 0px 10px; outline: none; }
      .yes { color: $colorGreenLight; }
      .no { color: $colorWhite; background-color: $colorGreenLight; }
    }
  }
}
.driver-infos-modal { margin-top: 10%;
  .modal-content { border: none !important; border-radius: 0px !important;
    .description { font-weight: 300; color: $colorGrayDark; }
    .rating { font-size: 24px; }
    .driver-infos { margin-top: 14px; }
    .driver-phone-and-license-number { margin-top: 24px; color: $colorGrayDark;
      i { margin-right: 10px; }
    }
    // .driver-image { width: 100%; }
    .driver-image { max-width: 120px; min-width: 80px; max-height: 120px; min-height: 80px; }
    .button-panel { margin-top: 30px;
      button { width: 150px; border: 1px solid $colorGreen; border-radius: 0px; margin: 0px 10px 0px 10px; outline: none; }
      .ok { color: $colorWhite; background-color: $colorGreenLight; }
    }
  }
}
.rating-modal { margin-top: 5%;
  .modal-content { border: none !important; border-radius: 0px !important;
    textarea { background-color: $colorGrayLight; border: none; resize: none; height: 100px; }
    .description { font-weight: 300; color: $colorGrayDark; }
    .small-rating { font-size: 24px; }
    .big-rating { font-size: 32px; }
    .driver-infos { margin-top: 14px; }
    // .driver-image { width: 100%; }
    .driver-image { max-width: 120px; min-width: 80px; max-height: 120px; min-height: 80px; }
    .button-panel { margin-top: 30px;
      button { width: 150px; border: 1px solid $colorGreen; outline: none; border-radius: 0px; margin: 0px 10px 0px 10px; outline: none; }
      .ok { color: $colorWhite; background-color: $colorGreenLight; }
    }
    textarea.form-control.note {font-style: italic;}
  }
}
.thanks-you-modal { margin-top: 5%;
  .modal-content { border: none !important; border-radius: 0px !important;
    textarea { background-color: $colorGrayLight; border: none; resize: none; height: 100px; }
    .description { font-weight: 300; color: $colorGrayDark; }
    .small-rating { font-size: 24px; }
    .big-rating { font-size: 32px; }
    .driver-infos { margin-top: 14px; }
    .driver-image { width: 100%; }
    .button-panel { margin-top: 30px;
      button { width: 150px; border: 1px solid $colorGreen; outline: none; border-radius: 0px; margin: 0px 10px 0px 10px; outline: none; }
      .ok { color: $colorWhite; background-color: $colorGreenLight; }
    }
  }
}
.verify-passcode-modal { margin-top: 5%;
  .modal-content { border: none !important; border-radius: 0px !important;
    .description { color: $colorGrayDark; }
    .passcode { width: 60px; height: 60px; margin: 10px; background-color: $colorGrayLight; text-align: center; border: none; outline: none; }
    .verify-button { margin-top: 20px; width: 300px; border-radius: 0px; color: $colorWhite; background-color: $colorGreenLight; }
    .edit-phone { margin: 20px; color: $colorGreenLight; cursor: pointer;
      &:hover { text-decoration: underline; }
    }
    .edit-phone-input { margin: 20px; padding: 10px; width: 300px; background-color: $colorGrayLight; border: none; height: 30px; }
    .resend-sms { color: $colorGreenLight;
      button { border: none; background: transparent; outline: none; text-decoration: underline; font-weight: 700; }
    }
  }
}
.select-working-account{
  .modal-header{
    border: none;
  }
  .modal-footer{
    border: none;
    text-align: center;
    button { border: none; background: transparent; outline: none; text-decoration: underline; font-weight: 700; color: $colorGreenLight;}
  }
}
.side-menu { position: absolute; top: 0%; right: -300px; width: 300px; z-index: 2000; background-color: $colorWhite; overflow-x: hidden; overflow-y: auto;
  .close { color: $colorGreenLight; margin: 10px 5px 0px; font-size: 30px; height: 56px; }
  .sub-header { color: $colorGrayDark; font-weight: 300; }
  .header { font-weight: 700; }
  .working-accounts {
    .accounts { margin-top: 1.5em; }
    .media {
      position: relative;
      &:not(:first-child) {
        padding-top: 10px;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0px;
          width: 100%;
          background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.1) 1.5em, rgba(0, 0, 0, 0.03) 100%);
          background: linear-gradient(to right, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.1) 1.5em, rgba(0, 0, 0, 0.03) 100%);
          height: 1px;
        }
      }
      .media-body {
        * { display: block; width: 160px; overflow: hidden; text-overflow: ellipsis; }
        span { color: $colorGrayDark; }
      }
      .media-left, .media-right, .media-body { vertical-align: middle;
        span.circle { width: 25px; height: 25px; }
      }
    }
  }
  .user-profile {
    .profile-image { border-radius: 30%; z-index: 1; }
    .file-input-field { position: absolute; width: 120px; height: 120px; opacity: 0; left: 50%; margin-left: -60px; z-index: 3; }
    .overlay { position: absolute; width: 120px; height: 120px; left: 50%; margin-left: -60px; z-index: 2; background: rgba(0, 0, 0, 0.4); border-radius: 30%; color: $colorWhite; line-height: 120px; font-weight: 300; font-size: 14px; }
    .info { margin-top: 10%;
      h4 { margin: 0px 0px 10px 0px; font-size: 1em; }
      input { margin: 0px 0px 10px 0px; outline: none; border: none; box-shadow: none; background-color: $colorGrayLight; }
      select { padding: 0px; border: none; box-shadow: none; font-weight: 700; margin-left: -5px; }
    }
  }
  .profile-control-panel {
    margin-top: 1.5em;
    h5 { color: $colorGreenLight; cursor: pointer;
      &:hover { text-decoration: underline; }
    }
  }
}
.beta_version_text{
  text-align: right;
  font-size: 16px;
  color: gray;
  margin-right: 10px;
}

div#event-banner {
    // width: 300px;
    // height: 200px;

    // position: absolute;
    // top:0;
    // bottom: 0;
    // left: 0;
    // right: 0;

    // margin: auto;

    // z-index: 5000;
    img {
      width: 100%;
      height: auto;
    }
    .modal-footer {
      text-align: center;
      border-top: none;
    }
    .button-panel { margin-bottom: 20px;
      button { width: 150px; border: 1px solid $colorGreen; border-radius: 0px; margin: 0px 10px 0px 10px; outline: none; }
      .ok { color: $colorWhite; background-color: $colorGreenLight; }
    }
    // .btn-primary { background-color: $colorGreenLight; color: $colorWhite; cursor: pointer;
    //   display: inline-block; line-height: 30px; margin-left: 30px;
    // }
}
.datetimepicker-component {
  position: relative;
}

.modal-dialog {
  z-index: 1025;
}

// .pac-container:after{
//   content:none !important;
// }
.bg-white{background-color: $colorWhite;}
// ======================================================
hr.slim{
  margin: 0.5em 0px;
}

.form-control{
  &.none-border{
    border: none;
  }
  &.gray{
    background-color: $colorGrayLight;
  }
}