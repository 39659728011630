.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .popup-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
  }

  .content-wrapper {
    border-radius: 16px;
    background-color: var(--color-green);
    text-align: center;
    // height: 200px;
    padding: 17px 20px;
    box-sizing: border-box;

    .icon {
      width: 63px;
      height: 63px;
      margin: 0 auto;
      background: var(--color-white);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -35px;
      left: 0;
      right: 0;
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.5);
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #ffdb00;
      margin: 30px 0 10px;
    }

    .content {
      font-size: 16px;
      color: var(--color-white);
    }
  }

  .btn-wrapper {
    display: flex;
    margin-top: 10px;

    .button {
      width: 155px;
      height: 40px;
      border-radius: 5px;
      background-color: var(--color-background-gray);
      color: var(--color-white);
      font-size: 16px;
      font-weight: 500;
      border: none;
      text-transform: capitalize;
      flex: 1
    }

    .confirm {
      background-color: var(--color-white);
      color: var(--color-green);
    }
  }

  &.Modal.visible .overlay {
    background: none
  }
}

.switch-tab-popup {
  position: absolute;

  .content-wrapper {
    height: auto;
  }
}

.SingleBookingLayout {
  .switch-tab-popup,
  .change-driver-details {
    zoom: 1;

    .popup-inner {
      width: 90%;

      .button {
        width: 48%;
      }
    }
  }
}

.schedule-conflict-modal .content-wrapper {
  .icon img {
    width: 67%;
  }
}

.switch-tab-popup-favorite .content-wrapper {
  .icon img {
    width: 80%;
  }
}

.schedule-conflict-modal .popup-inner,
.MultipleBookingLayout .change-driver-details .popup-inner {
  width: 320px;
}
