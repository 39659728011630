
.BookingWizard .Content {
  .discount-code.block {
    box-shadow: none;
    padding: 0px;
  }
}

.discount-code-container {
  .Line-bg {
    margin: 0px 15px;
  }
}

.discount-code {
  box-shadow: none;
  color: #444444;
  font-size: 13px;

  .grow-2 {
    flex-grow: 2;
  }
  
  .discount-code-title {
    align-items: center;
    font-size: 14px;

    .icon-wrapper {
      flex-grow: 2;
      color: #7c7c7c;
    }
  }
  
  .input-container {
    .Input {
      margin-bottom: 15px;
    }
  }

  .icon-wrapper {
    vertical-align: middle;

    .Icon {
      font-size: 20px;
    }
  }

  .action-button {
    cursor: pointer;

    &.apply {
      color:  var(--color-green);

      &.disabled {
        color:  #444444;
        cursor: default;
        opacity: 0.3;
      }
    }

    &.remove {
      color:  var(--color-red);
    }
  }

  &.valid {
    .Input {
      background-color: rgba(63, 174, 41, 0.1);
      
      input {
        color: var(--color-green);
      }
    }

    .message {
      color: var(--color-green);
    }
  }

  &.invalid {
    .Input {
      background-color: rgba(244, 67, 54, 0.1);
      
      input {
        color: var(--color-red);
      }
    }
    .message {
      color: var(--color-red);
    }

  }

  .Input {
    input {
      border-radius: unset;
    }
  }
}

// For multiple booking
.MultipleBookingLayout {

  .discount-code-container {
    margin-top: 10px;

    .Line-bg {
      margin: 0px;
    }
  }
  
  .discount-code {
    .discount-code-title,
    .input-container {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}