body.webapp {
  .booking-details {
    position: relative;
    .ui.list {
      .content { font-weight: 300; }
      &.details {
        .item {
          .title { color: $colorGrayDark; display: inline-block;  min-width: 140px; height: 30px; }
          .value { display: inline-block; }
        }
      }
    }
    .breadcrumb {
      margin: 0px 2rem;
      padding-left: 0px;
      background: none;
      .section {
        color: $colorGreen; font-weight: 300;
        &.active { color: $colorBlack; font-weight: 300; }
      }
      .icon { color: $colorGreenLight; font-weight: 300; }
    }
    .details-box {
      margin-top: 0px !important;
      background-color: $colorGrayLight;
      border: 1px solid $colorGray;
      padding: 1rem 2rem;
      h3 {
        border-bottom: 2px solid $colorGreen; color: $colorBlack; font-weight: 300;
      }
      .icon {
        &.star { font-size: 1.2rem; color: $colorGreen; }
        img { max-height: 22px; }
      }
      .ui.list .item a.favorite-button,
      .ui.list .item a.banned-button {
        color: $colorWhite !important;
        &:hover, &:focus, &:active { color: $colorWhite !important; }
      }
    }
    .locations-box {
      margin-top: 1rem;
      table {
        td {
          vertical-align: top;
          .image.floated {
            + .content { margin-left: 40px; }
          }
          a:not(:first-child) {
            .ui.image { margin-top: 10px; border: 1px solid $colorGray; }
          }
        }
      }
    }
    .info-box {
      h4 { background-color: $colorBlack; color: $colorWhite; padding: 0.5em 2rem; font-weight: 300; margin-bottom: 1em; font-size: 1rem; }
      .content {
        margin-bottom: 1rem;
        .icon {
          &.star { font-size: 1.5rem; color: $colorGreen; }
        }
      }
    }
    table.fee-table {
      border: 1px solid $colorGray;
      background-color: $colorGrayLight;
      padding: 0px 1em;
      @include custom-rounded(0px);
      td:not(:first-child) { text-align: right; }
      > tbody {
        > tr {
          td:first-child { padding-left: 0px; }
          td:last-child {
            padding-right: 0px;
            -webkit-box-shadow: none !important;
               -moz-box-shadow: none !important;
                    box-shadow: none !important;
          }
          &:last-child td { border-top: 2px solid $colorGreen; font-size: 1.5em; }
          &.group-title {
            > td { font-weight: 600;  }
            + tr > td { border-top: none; padding-left: 1em; }
          }
          > td:not(:only-child):first-child { font-weight: 600; }
        }
      }
      tbody tr:last-child {
        border-bottom: none !important;
      }
      table {
        margin: -0.7em 0;
        border: none !important;
        td:first-child {
          &:before {
            content: "-";
            padding-right: 10px;
          }
        }
      }
    }
  }
  .list-view{
    position: relative;
    .datetime-picker{
      img{
        height: 37px;
        width: 47px;
      }
    }
  }
}