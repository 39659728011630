.adding-fund-page {
  box-sizing: border-box;

  .border {
    border: 1px solid var(--color-gray);
  }

  justify-content: center;
  align-items: center;

  .default-border-radius {
    border-radius: var(--radius);
  }
}

.adding-fund-title-bar {
  box-sizing: border-box;
  font-weight: bold;
  width: 100%;
  background-color: #efefef;
  padding: 5px 20px;

  .back-button {
    padding: 10px 13px;
    border:  1px solid var(--color-green);
    align-self: center;

    .Icon {
      vertical-align: middle;
      font-size: 24px;
      color: var(--color-green);
    }
  }

  .title-bar-text {
    font-size: 24px;
    align-self: center;
    color: #444444;
  }
}

.adding-fund-comp {
  // --color-light-gray: #dbdbdb;
  --color-black: #282828;
  --color-black-s: #a1a1a1;
  --color-gray: #d8d8d8;
  --color-gray-s: #dbdbdb;
  --color-green-l: #02751a;

  font-size: 15px;
  color: var(--color-black-s);
  margin-top: 40px;
  max-width: 600px;
  min-width: 400px;

  .adding-fund-step {
    .adding-fund-step-block {
      padding: 20px;
      margin-bottom: 20px;
      background-color: #f9f9f9;

      .block-title {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: initial;
      }

      &.multi-block {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
    }

    .block-item {
      padding: 20px 20px 0px 20px;

      &.administrative-fee {
        background-color: #ebf6e9;
        padding: 5px 20px;
        margin-top: 10px;
      }
    }

    .administrative-fee {
      font-size: 12px;
      text-align: center;
      color: #282828;
      align-items: center;
      justify-content: center;

      .Icon {
        color: var(--color-green);
        vertical-align: middle;
        margin-left: 5px;
      }
    }
    &.step-enter-amount {
      border: 1px solid var(--color-gray);
      border-radius: var(--radius);
  
      .adding-fund-step-block {
        margin-bottom: 0;
      }
    }
  }

  .enter-amount {
    font-size: 16px;
  }

  .enter-amount-input-wrap {
    align-items: center;

    & > div,
    & > input {
      padding: 10px;
    }
    
    input {
      flex-grow: 2;
      border: none;
      background: none;
      font-size: 16px;
      background-color: #ffffff;
      border-radius: var(--radius);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    input:focus {
      border: none;
      outline: none;
    }
  }

  .enter-amount-caption {
    border-right: 1px solid var(--color-gray);

    img {
      max-height: 18px;
      width: 26px;
    }

    > div,
    > span {
      align-self: center;
    }

    .Icon {
      font-size: 18px;
    }
  }

  .enter-amount.error {
    .border {
      border-color: var(--color-red);
    }

    input {
      color: var(--color-red);
      background: none;

      &::placeholder {
        color: red !important;
        font-style: normal;
      }
    }

    .enter-amount-input-wrap {
      background-color: rgba(240, 68, 51, 0.1);
    }

    .error-message {
      color: var(--color-red);

      .Icon {
        margin-right: 5px;
        font-size: 18px;
      }

      > i,
      > span {
        align-self: center;
      }
    }
  }

  .enter-amount.disable {
    .enter-amount-input-wrap {
      background-color: var(--color-gray-s);

      input {
        background-color: var(--color-gray-s);
      }
    }
  }

  .enter-amount-label-text {
    flex-grow: 2;
  }
  

  .enter-amount-label-change {
    color: var(--color-green-l);
  }
  
  .predefined-amount {
    max-width: 450px;
    font-size: 14px;
    .predefined-amount-list {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &.large {
      max-width: 550px;
    }

    .predefined-amount-list-item {
      color: #282828;
      border: 1px dashed var(--color-black-s);
      min-width: 105px;
      width: 31%;
      padding: 12px 10px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 15px;

      &.selected {
        background-color: var(--color-green);
        border-color: var(--color-green-l);
        color: var(--color-white);
        border-style: solid;
      }
      
      &.placeholder {
        visibility: hidden;
        height: 0;
        padding: 0;
        margin: 0;
        border-top-width: 0;
        border-bottom-width: 0;
      }
    }
  }
  
  .proceed-button {
    box-sizing: border-box;
    color: var(--color-white);
    font-size: 15px;
    width: 100%;
    text-align: center;
    background-color: var(--color-green);
    padding: 10px 20px;
  }
}

.iframe-2c2p {
  width: 100%;
  height: 100vh;
  border: 0;
}
.topup-modal.Modal {
  .Popup {
    width: 500px;
    .Popup-Booking-Custom {
      min-width: 460px;
      max-width: 460px;
    }
  }
}