.vehicles-selection {
  z-index: 22;

  .popup-inner {
    width: 600px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
  }

  .popup-content {
    max-height: 376px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .title {
    font-size: 17px;
    font-weight: bold;
    color: var(--color-white);
    padding: 15px 20px;
    background-color: #40ae29;
  }

  .selection-wrapper {
    padding: 20px;

    .List-Item {
      display: flex;
      color: #282828;
      
      &:first-child {
        margin-bottom: 10px;
      }

      &.checked label {
        font-weight: 500;
      }
    }
    
    .recommended-label {
      font-size: 11px;
      font-weight: 500;
      color: #444444;
      padding: 2px 6px;
      border-radius: 3px;
      text-transform: uppercase;
      background-color: var(--color-yellow);
      height: 17px;
      box-sizing: border-box;
      margin-left: 10px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      line-height: 1;
    }
  }

  .service-list {
    padding-bottom: 30px;
  }

  .service-row {

    .category-title {
      background-color: #f1f1f1;
      color: #282828;
      font-size: 12px;
      padding: 5px 20px;
    }

    .category-content {
      padding: 20px 0;
      margin: 0 20px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid #d8d8d8;
      }
    }

    .category-image {
      flex: 1;
      display: flex;
      align-items: center;

      img {
        max-width: 60px;
      }

      .Vehicle-Service-Type-Popup-Eye {
        margin-left: 5px;

        img {
          cursor: pointer;
        }
      }
    }

    .vehicle-list {
      flex: 2;
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: 10px;
    }

    .vehicle-checkbox {
      display: flex;

      &:before {
        width: 210px;
      }

      .Overlay-Checkbox {
        margin-right: 10px;

        &.Disable label {
          &:before {
            background-color: var(--color-background-gray) !important;
            border-color: var(--color-background-gray) !important;
          }

          i {
            display: none;
          }
        }
      }

      label {
        font-size: 16px;
        color: #282828;
        cursor: pointer;
      }

      &.disable-tooltip {
        &[data-tooltip]:hover:before,
        &[data-tooltip]:hover:after {
          display: none;
        }
      }
    }

    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after {
      display: block;
      z-index: 10001;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0;
    border-top: 1px solid var(--color-light-gray);
    background-color: #f9f9f9;

    .button {
      max-width: 275px;
      height: 40px;
      font-size: var(--font-size-b);
      font-weight: 500;
      color: #4a4a4a;
      background-color: #cecece;
      text-align: center;
      padding: 10px;
    }

    .confirm {
      color: var(--color-white);
      background-color: #40ae29;
    }

    .disable {
      background-color: #a5a5a5;
      pointer-events: none;
    }
  }
}