$mainColors: (
  "0": #1296fe,
  "1": #fe7c9c,
  "2": #e1b12c,
  "3": #d5382d,
  "4": #42ba71,
  "5": #dc71ef,
  "6": #7582bf,
  "7": #d3603d,
  "8": #273c75,
  "9": #7f8c8d,
  "10": #3dcecc,
  "11": #b333c8,
  "12": #6ab04c,
  "13": #ff9800,
  "14": #079992,
  "15": #4b4b4b,
  "16": #78e08f,
  "17": #775fff,
  "18": #a278d8,
  "19": #d8c266,
);

$subColors: (
  "0": #0d70bf,
  "1": #bf5d75,
  "2": #a98521,
  "3": #a02a21,
  "4": #318c55,
  "5": #a555b3,
  "6": #58618f,
  "7": #9e482d,
  "8": #1d2d58,
  "9": #5f696a,
  "10": #2d9b99,
  "11": #862696,
  "12": #4f8439,
  "13": #c07200,
  "14": #05736d,
  "15": #383838,
  "16": #5aa86b,
  "17": #5947c0,
  "18": #795aa2,
  "19": #a2924c,
);

@each $mainColors, $i in $mainColors {
  .booking-number {
    &.color-#{$mainColors} {
      background-color: $i;

      &:after {
        background-color: $i;
        border-color: $i;
      }
    }
  }

  .color-#{$mainColors} {
    .circle:not(.location-dot-pickup),
    .circle-multiple {
      background-color: $i;
    }

    .sortable-placeholder {
      background-color: $i;
      opacity: 0.5;
    }
  }

  .locations-idx-#{$mainColors}:before {
    background-color: $i;
    opacity: 0.1;
  }

  .cargo-info .color-#{$mainColors} {
    color: $i;
  }

  .marker-wrapper .marker-#{$mainColors} {
    background-color: $i;
  }
}

@each $subColors, $i in $subColors {
  .color-#{$subColors} {
    .circle:not(.location-dot-pickup),
    .circle-multiple {
      &.show-detail,
      &:hover {
        background-color: $i;
        outline-color: $i;
      }
    }
  }

  .marker-wrapper {
    .marker-#{$subColors} {
      &:hover,
      &.hovering-#{$subColors} {
        background-color: $i;
        outline-color: $i;
      }
    }
  }
}

.step-plan-wrapper {
  display: flex;
  height: calc(100vh - 214px);
  overflow: hidden;
  position: relative;

  .content-wrapper {
    flex: 2;
    position: relative;
    border-right: solid 1px #dfdfdf;
    display: flex;
    flex-direction: column;

    .toggle-map-btn {
      width: 36px;
      height: 36px;
      box-shadow: 0 2px 4px 0 rgba(192, 192, 192, 0.5);
      background-color: var(--color-white);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      position: absolute;
      top: 10px;
      right: -36px;
      cursor: pointer;
      z-index: 2;

      .Icon {
        font-size: 38px;
        color: #666666;
      }
    }

    .arrow-left {
      right: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      .Icon {
        transform: rotate(180deg);
      }
    }
  }

  .empty-plan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 420px;

    p {
      margin: 20px 0 30px;
      font-size: 17px;
      color: #7c7c7c;
    }

    .Button {
      width: 220px;
      font-size: var(--font-size-b);
      font-weight: 500;
    }
  }
}

.bookings-wrapper {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.bookings-header {
  display: flex;
  justify-content: space-between;
}

.route-action {
  font-size: 13px;
  color: var(--color-border-green);
  text-decoration: underline;
  cursor: pointer;
}

.total-bookings {
  font-size: 16px;
  color: #282828;
  margin-left: 20px;
  font-weight: normal;

  .Overlay-Checkbox {
    margin-right: 10px;
  }
}

.booking-mark {
  display: flex;

  .Overlay-Checkbox {
    margin-top: 2px;

    &.Green-Checkbox label {
      padding-left: 16px;

      &:before {
        border: 1px solid var(--color-border-gray);
        box-sizing: border-box;
      }
    }

    i {
      width: 16px;
      line-height: 16px;
      font-size: 16px;
    }
  }
}

.booking-wrapper {
  border: solid 1px #dfdfdf;
  padding: 15px 20px;
  border-radius: 4px;
  background-color: var(--color-white);
  margin: 10px -5px 10px 0;
  position: relative;

  .cargo-info {
    margin: 10px 0 10px 26px;

    .text .cargo-utilised {
      font-weight: bold;
    }

    .overload {
      color: var(--color-red);
      font-weight: 500;
      text-transform: uppercase;
      display: flex;

      .info-icon {
        width: 13px;
        margin-left: 5px;
        cursor: pointer;
        position: relative;
        text-transform: none;

        img {
          width: 100%;
        }

        &:before {
          font-size: 12px;
          font-weight: 400;
          width: 200px;
          border-radius: 6px;
          position: absolute;
          padding: 5px 23px;
        }
      }
    }
  }

  .section-handle {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
  }

  .drag-handle,
  .route-handle {
    cursor: pointer;
    align-items: center;
    color: var(--color-border-green);
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    display: flex;
    padding: 6px 3px;
    height: 14px;

    .title {
      display: none;
    }

    &:hover {
      background-color: rgba(64, 174, 41, 0.1);

      .title {
        display: block;
      }
    }

    .order-icon {
      display: block;
      width: 12px;
      height: 13px;
      margin-left: 5px;
    }
  }

  &.ui-sortable-helper {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }

  &.last-booking {
    .circle,
    .circle-multiple {
      &:after {
        top: -13px;
        border-top: none;
        border-bottom: solid 1px var(--color-light-gray);
        border-right: solid 1px var(--color-light-gray);
        border-left: none;
      }
    }

    .location-dot .location-popup {
      top: -11px;
      transform: translate(-50%, -100%);

      &:after {
        top: unset;
        bottom: -15px;
      }
    }

    .location-dot {
      &:first-child,
      &.firstLocations,
      &:last-child,
      &.lastLocations {
        .location-popup {
          transform: translate(0, -100%);
        }
      }
    }
  }

  .explain-failed {
    background-color: #f1f1f1;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    margin: 5px -20px -15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    p {
      font-size: 12px;
      color: #282828;
      margin: 0;
    }

    .tooltip {
      width: 14px;
      height: 14px;
      font-size: 14px;

      &:before {
        width: 420px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.booking-selected {
  border-color: var(--color-green);
}

.booking-number {
  font-size: 13px;
  color: var(--color-white);
  text-transform: uppercase;
  min-width: 80px;
  height: 20px;
  line-height: 21px;
  position: relative;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
  box-sizing: border-box;
  margin-left: 10px;
  border-radius: 3px;

  &:after {
    content: "";
    display: block;
    box-shadow: none;
    border-top: 2px solid;
    border-right: 2px solid;
    position: absolute;
    right: -5px;
    transform: rotate(45deg) skew(15deg, 15deg);
    width: 9px;
    height: 8px;
    top: 5px;
  }
}

.stop-by {
  font-size: 13px;
  margin: 10px 24px 10px 0;

  img {
    margin-right: 12px;
    vertical-align: text-top;
  }

  .value {
    font-weight: 500;
    margin-left: 5px;
  }

  .pickup-time {
    display: flex;
    align-items: center;

    .arrow-right {
      margin-left: 10px;
    }
  }
}

.divine {
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}

.locations-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
  }
}

.sortable-placeholder {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.circle {
  width: 22px;
  height: 22px;
  color: var(--color-white);
  text-align: center;
  line-height: 22px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.circle-multiple {
  color: var(--color-white);
  height: 22px;
  line-height: 22px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  border-radius: 15px;

  span {
    display: inline-block;
    width: 22px;
    text-align: center;
    position: relative;

    &:not(:last-of-type):after {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 14px;
      background-color: var(--color-white);
      top: 4px;
      right: 0;
    }
  }
}

.circle,
.circle-multiple {
  &.show-detail,
  &:hover {
    outline: solid 1px;
    outline-offset: 3px;

    .location-popup {
      display: block;
    }

    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 32px;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
      border-top: solid 1px var(--color-light-gray);
      border-left: solid 1px var(--color-light-gray);
      background-color: var(--color-white);
      z-index: 1;
    }
  }
}

.location-dot {
  height: 30px;
  flex: 1;
  display: flex;
  justify-content: center;

  &:first-child,
  &:last-child {
    flex: 0.5;
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }

  &:first-child,
  &.firstLocations {
    .location-popup {
      left: 0;
      transform: translate(0, 0);

      &:after {
        left: 0;
        transform: unset;
      }
    }
  }

  &:last-child,
  &.lastLocations {
    .location-popup {
      transform: unset;
      left: unset;
      right: 0;

      &:after {
        left: unset;
        right: 0;
        transform: unset;
      }
    }
  }

  .location-popup {
    display: none;
    position: absolute;
    top: 33px;
    left: 50%;
    width: 340px;
    color: #444;
    border: solid 1px var(--color-light-gray);
    border-radius: 4px;
    background-color: var(--color-white);
    transform: translate(-50%, 0);
    z-index: 1;
    font-size: 13px;
    box-sizing: border-box;
    text-align: left;
    cursor: text;
    box-shadow: 0 2px 10px 0 rgba(149, 149, 149, 0.5);

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      left: 50%;
      top: -15px;
      transform: translateX(-50%);
    }

    .divine {
      margin: 15px 0;
    }

    .contact {
      display: flex;
      align-items: center;
      font-weight: normal;
      line-height: normal;

      & .Icon.b {
        color: var(--color-background-gray);
        font-size: 20px;
        margin-right: 4px;
      }

      b {
        margin-left: 5px;
      }

      img {
        margin: 0 8px 0 2px;
      }
    }

    .popup-content {
      position: relative;
      padding: 16px 0 16px 32px;
      margin: 0 16px;

      &:not(:last-child) {
        border-bottom: 1px solid #dfdfdf;
      }
    }

    .custom-scroll {
      max-height: 245px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .address,
  .dimentions-title,
  .value {
    font-weight: 500;
    color: #444;
    margin: 0;
  }

  .address {
    line-height: normal;
    margin-bottom: 6px;
  }

  .location-number {
    position: absolute;
    top: 14px;
    left: -2px;
  }

  .dimentions-content {
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 0.7fr 1fr;

    .text,
    .value {
      font-size: 12px;
    }

    .text {
      font-weight: normal;
    }

    .vehicle-type {
      line-height: 1.5;
    }
  }

  .from-location {
    padding: 16px 8px 16px 48px;

    .location-number {
      left: 16px;
      top: 12px;
    }
  }
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-green);
    outline: none;
  }

  &::-webkit-scrollbar-track {
    background-color: #d7edd7;
  }
}

// SummaryPickup
.summary-pickup {
  padding: 15px 38px;
  border-bottom: solid 1px #dfdfdf;
  color: #444;
  background-color: var(--color-white);
}

.pickup-address {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .location-dot-pickup {
    margin-right: 8px;
  }
}

.plan-time {
  display: flex;
  align-items: center;
  margin: 15px 0 15px 4px;

  img {
    margin-right: 13px;
  }

  .time-content,
  .time-text {
    font-size: 13px;
  }

  .time-content {
    display: flex;
    align-items: center;

    .lds-spinner {
      color: var(--color-border-green);
      margin-left: 10px;
    }

    .time-text {
      margin-left: 5px;
    }

    .undo {
      color: var(--color-light-red);
      padding: 3px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: rgba(240, 68, 51, 0.1);
      }

      img {
        width: 13px;
        height: 13px;
        vertical-align: text-top;
        margin-right: 5px;
      }
    }
  }

  .time-text {
    font-weight: 500;
    margin-right: 10px;
  }

  .est-time {
    border: none;
    border-radius: 4px;
    padding: 4px 0;
    height: auto;
    margin-left: 3px;

    .Icon {
      font-size: 12px;
      color: var(--color-green);
    }
  }
}

.cargo-info {
  display: flex;
  margin-left: 30px;

  .text {
    font-size: 13px;
    position: relative;
    margin-right: 20px;

    &:not(:first-child)::before {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background-color: var(--color-background-gray);
      position: absolute;
      top: calc(50% - 2px);
      left: -12px;
      border-radius: 50%;
    }

    &:last-child {
      margin: 0;
    }
  }

  .value {
    font-weight: 500;
    margin-left: 5px;
  }
}

// Dropdopwn vehicle types
.vehicle-types {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .dropdown-wrapper {
    margin-left: 12px;
    position: relative;
    min-width: 180px;

    .overlay {
      position: fixed;
      opacity: 0.25;
      background-color: #000;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 23;
      cursor: pointer;
    }
  }

  .dropdown {
    height: 30px;
    padding: 6px 30px 5px 10px;
    border-radius: 4px;
    border: solid 1px var(--color-light-gray);
    background-color: var(--color-white);
    font-size: 16px;
    color: #282828;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;

    &.error {
      border-color: var(--color-border-red);
    }

    img {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }

  .dropdown-options {
    width: 320px;
    min-height: 150px;
    max-height: 485px;
    position: absolute;
    top: 0;
    overflow-y: auto;
    background: white;
    z-index: 24;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &::-webkit-scrollbar {
      width: 9px;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-dark-green);
      outline: none;
    }

    &::-webkit-scrollbar-track {
      background-color: #d7edd7;
    }
  }

  .dropdown-option {
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 14px;
    font-size: 15px;

    p {
      margin: 0 0 0 11px;
    }

    &:last-child {
      border-top: 1px solid #d8d8d8;
    }

    &:first-child {
      border-bottom: 1px solid #d8d8d8;
    }

    &:not(:last-child),
    &:not(:first-child) {
      border: 1px solid #d8d8d8;
    }

    &:hover {
      background-color: rgba(14, 115, 15, 0.1);
    }

    &.active {
      background-color: var(--color-dark-green);
      border-color: var(--color-dark-green);
      color: var(--color-white);
    }

    img {
      max-width: 72px;
      max-height: 27px;
    }
  }

  .undo {
    color: var(--color-light-red);
    padding: 3px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: rgba(240, 68, 51, 0.1);
    }

    img {
      width: 13px;
      height: 13px;
      vertical-align: text-top;
      margin-right: 5px;
    }
  }
}

// MAP
.map-wrapper {
  width: 35%;
  min-width: 513px;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}

.slide-right {
  min-width: 0px;
  animation: slide-right 0.5s forwards ease-in;
}

@keyframes slide-right {
  from {
    width: 35%;
  }

  to {
    width: 0%;
  }
}

.slide-left {
  min-width: 0px;
  animation: slide-left 0.5s forwards ease-in;
}

@keyframes slide-left {
  from {
    width: 0%;
  }

  to {
    width: 35%;
    min-width: 513px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .SmartPlanner {
    height: calc(100vh - 48px);
  }

  .step-plan-wrapper {
    height: calc(100vh - 180px);
  }
}

.plan-time,
.stop-by {
  .arrow-right {
    position: relative;
    background: var(--color-border-gray);
    width: 13px;
    height: 2px;
    border-radius: 2px;
    transform-origin: right center;
    margin-right: 10px;

    &:before {
      transform: rotate(140deg);
    }
    &:after {
      transform: rotate(-140deg);
    }

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      left: calc(13px - calc(13px / 20));
      background: var(--color-border-gray);
      width: 8px;
      height: 2px;
      transform-origin: calc(13px / 20) center;
      border-radius: 2px;
    }
  }
}

.rebundle-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  padding: 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 63px;
  background-color: #ebf6e9;

  .explain {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    p {
      color: #282828;
      font-size: 16px;
    }
  }

  .btn-next {
    font-size: 16px;
    font-weight: bold;
    width: 170px;
  }

  &.overload-section {
    background-color: #fdecea;
  }

  .btn-transparent img {
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }
}

.btn-transparent {
  border: none;

  &:hover {
    background-color: transparent;
  }
}

.popup-wrapper.undo-all {
  .popup-content {
    display: flex;
    padding: 18px;

    p {
      margin: 0 0 0 14px;
      font-size: 20px;
      color: #282828;
    }
  }

  .btn-wrapper {
    justify-content: flex-end;
    padding: 10px 20px 20px;
    border-top: none;

    .button {
      padding: 0;
    }

    .back {
      width: 120px;
      background-color: var(--color-white);
      border: solid 1px var(--color-border-gray);
      border-radius: 5px;
    }

    .confirm {
      width: 190px;
      margin-left: 10px;
    }
  }
}

.marker-wrapper {
  position: relative;
  height: 45px;

  .marker {
    color: var(--color-white);
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    border-radius: 15px;
    outline: 2px solid var(--color-light-gray);
    border: 3px solid var(--color-white);

    span {
      display: inline-block;
      width: 22px;
      text-align: center;
      position: relative;

      &:not(:last-of-type):after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 14px;
        background-color: white;
        top: 4px;
        right: 0;
      }
    }
  }

  .rectangle {
    width: 2px;
    height: 16px;
    border-radius: 3.1px;
    background-color: var(--color-background-gray);
    position: absolute;
    left: 50%;
    top: 29px;
    transform: translateX(-50%);
  }

  .oval {
    width: 6px;
    height: 6px;
    border: 2px solid var(--color-background-gray);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    background-color: var(--color-light-gray);
  }

  .single-marker {
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);

    & ~ .rectangle {
      top: 28px;
    }

    & ~ .oval {
      top: 38px;
    }
  }

  #pickup-marker {
    background-color: #00b9e6;

    &:hover,
    &.hovering {
      outline-color: #00b9e6;
    }
  }
}
