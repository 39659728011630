
//= require_self

//= require animsition
//= require semantic-ui/dist/semantic
//= require alertify/themes/alertify.default
//= require alertify/themes/alertify.core
//= require eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker

.background-image {
  // @include custom-background-body('background-preload3', 'png');
}
#business-page{
  .ui.disabled.input{
    opacity: 0.45;
    input{
      pointer-events: none;
    }
  }
  .ui.green.header{
    color: $colorGreen !important;
  }
}