.topup-modal {
  &.success {
    .title {
      h5 {
        font-size: 22px;
      }
    }
  
    .success_message {
      font-size: 14px;
    }
    
    .Button {
      font-size: 18px;
    }
  }

  &.failed {
    .title {
      h5 {
        font-size: 22px;
      }
    }
  
    .Popup-Booking-Custom {
      .failed_message {
        font-size: 16px;
      }
    }
  
    .Button {
      font-size: 18px;
    }
  }
}
