.live-tracking-tally__status {
  width: auto;
  padding-right: 0 !important;
}
.live-tracking-tally__thead {
  display: flex;
  div {
    &:first-child {
      flex: 1;
    }
  }
}
.live-tracking-tally__badge {
  height: 30px;
  margin: 3px 0 3px 15px;
  padding: 8px 10px 7px;
  border-radius: 4px;
  background-color: white;
  font-size: 13px;
  font-weight: 500;
  .white{
    border-radius: 5px;
    padding: 1px 6px;
    border: 1px solid white;
    color: white;
  }
  .green{
    background-color: white;
    border: none;
  }
  .red{
    background-color: white;
    border: none
  }
}
.live-tracking-tally__time {
  padding-right: 0px !important;
  line-height: 1.5;
  font-size: 15px;
  white-space: pre-line;
  div {
    &:first-child {
      padding-top: 2px;
    }
  }
}
.live-tracking-tally__at-pickup {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: white;
}