.tab-content-wrapper.popup-wrapper {
  position: fixed;
  width: 100%;

  .popup-inner {
    width: 350px;
    overflow: visible;
    border-radius: 6px;
  }

  .content-wrapper {
    border-radius: 6px;

    .title {
      color: var(--color-yellow);
      font-size: 20px;
      font-weight: 400;
    }

    .content {
      line-height: 24px;
    }

    .icon {
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 60%;
      }
    }
  }
}
