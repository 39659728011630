.locating-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;

  &__background {
    flex: 1;
    background: linear-gradient(0deg, var(--color-timetype) 0%, var(--color-timetype-sub) 100%);
    filter: brightness(var(--brightness));
  }

  &__content {
    height: var(--height);
    background: var(--color-timetype);
    filter: brightness(var(--brightness));
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--color-timetype) 0%, var(--color-timetype-sub) 100%);
    filter: brightness(var(--brightness));
  }

  .loading {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 64px;
    height: 64px;
  }

  .loading-text {
    color: var(--color-white);
    font-size: 15px;
    width: max-content;
    padding: 30px;
    padding-left: 104px;

    h4 {
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 5px 0;
    }

    p {
      font-style: italic;
      margin: 0;
    }
  }

  .ring {
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    animation: pulse 4s infinite;
  }

  .ring-0 {
    animation: none;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .ring-2 {
    animation-delay: 1s;
  }

  .ring-3 {
    animation-delay: 2s;
  }

  .ring-4 {
    animation-delay: 3s;
  }

  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(4.2, 4.2);
      opacity: 0;
    }
  }

  .svgPieTimer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
  }

  .svg-loader {
    fill: var(--color-timetype);
  }

  .svg-border {
    fill: var(--color-timetype);
  }
}
